import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DateIcon from '../../assets/images/date.png';
import TimeIcon from '../../assets/images/time.png';
import ClockIcon from '../../assets/images/clock.png';
import { useGetAppointments } from '../../hooks';
import ReactModal from 'react-modal';
import CalenderViewAppointmentForm from '../../components/templates/calendar-view-appointment-form/calendar-view-appointment-form';
import { Spinner } from '../../components/atoms';
import { AddClientForm } from '../scheduler-clients/add-client-form';
import { Navigate } from 'react-big-calendar';
import LeftIcon from '../../assets/images/left-arw.png';
import RightIcon from '../../assets/images/right-arw.png';
const localizer = momentLocalizer(moment);

function MyCalendar({ isFirstModalOpen, setFirstModalOpen, openFirstModal }) {
  const views = {
    month: true,
    week: true,
    day: true,
    // Set the agenda view to false to hide it
    agenda: false,
  };
  const [filter, setFilter] = useState({
    filter: {
      startTimeGteq: moment().startOf('month').toISOString(),
      startTimeLteq: moment().endOf('month').toISOString(),
    },
    pagination: {},
  });

  const { data, refetch, loading } = useGetAppointments(filter, true);
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [clickedDate, setClickedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [activeDate, setActiveDate] = useState(new Date());
  const [newClientData, setNewClientData] = useState('');
  const updateFilter = useCallback((view, date) => {
    let start, end;
    if (view === 'month') {
      start = moment(date).startOf('month');
      end = moment(date).endOf('month');
    } else if (view === 'week') {
      start = moment(date).startOf('week');
      end = moment(date).endOf('week');
    } else if (view === 'day') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day');
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        startTimeGteq: start.toISOString(),
        startTimeLteq: end.toISOString(),
      },
    }));
  }, []);

  useEffect(() => {
    updateFilter(currentView, currentDate);
  }, [currentView, currentDate, updateFilter]);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (data?.appointments?.nodes) {
      const events = data.appointments.nodes.map((appointment) => {
        const start = new Date(appointment.startTime);
        const end = new Date(
          start.getTime() + appointment.sellerService.durationInMinutes * 60000
        );
        return {
          service: appointment.sellerService.name,
          title: `${appointment.buyer.formalName} - ${appointment.sellerService.name}`,
          start: start,
          end: end,
          buyerName: appointment.buyer.formalName,
          status: appointment.status,
          durationInMinutes: appointment.sellerService.durationInMinutes,
        };
      });
      setEvents(events);
    }
  }, [data]);

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const handleNavigate = (date, view) => {
    if (showEventPopup) {
      return; // Exit the function early if the event popup is shown
    }
    setCurrentDate(date); // Set the current date if the event popup is not shown
    setCurrentView(view); // Set the current view
  };

  const [isSecondModalOpen, setSecondModalOpen] = useState(false);

  const closeFirstModal = () => {
    setNewClientData('');
    setFirstModalOpen(false);
  };
  const openSecondModal = () => {
    closeFirstModal();
    setSecondModalOpen(true);
  };
  const closeSecondModal = () => setSecondModalOpen(false);

  // custom event
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [showEventPopup, setShowEventPopup] = useState(false);

  const handleEventClick = (event) => {
    const clickedDate = moment(event.start).format('MMMM D, YYYY');
    setShowEventPopup(true);
    setClickedDate(clickedDate);
    setSelectedDateEvents([event]);
  };

  const handleSelectSlot = ({ start, end, action }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (action === 'click' || action === 'touch' || action === 'select') {
      // Check if the selected start date is before today
      if (start < today) {
        // Optionally show a message to the user
        console.log('You cannot select a past date.');
        return;
      }
      setActiveDate(start); // or end, depending on your requirement
      openFirstModal(); // or any other action you want to perform
    }
  };

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    let colorStyle = {};

    // Default color
    if (isSelected) {
      colorStyle = {
        backgroundColor: '#2f80ed',
      };
    }

    // Set colors based on status
    switch (event.status) {
      case 'accepted':
        colorStyle.backgroundColor = '#e2f5e2';
        colorStyle.color = '#2c802a';
        colorStyle.border = '1px solid #2c802a';
        break;
      case 'canceled':
      case 'rejected':
        colorStyle.backgroundColor = '#ffdad2';
        colorStyle.color = '#d9000d';
        colorStyle.border = '1px solid #d9000d';
        break;
      case 'completed':
        colorStyle.backgroundColor = '#f4f4f4';
        colorStyle.color = '#8f8e8e';
        colorStyle.border = '1px solid #8f8e8e';
        break;
      default:
        break;
    }

    return {
      style: { ...colorStyle },
    };
  }, []);

  const onShowMore = useCallback((events, date) => {
    const clickedDate = moment(date).format('YYYY-MM-DD');
    const filteredEvents = events.filter(
      (ev) => moment(ev.start).format('YYYY-MM-DD') === clickedDate
    );

    setSelectedDateEvents(filteredEvents);
    setShowEventPopup(true);
    setClickedDate(moment(date).format('MMMM D, YYYY')); // Use the `date` argument to format the clicked date
  }, []);

  const customToolbar = ({ label, onNavigate, view, onView }) => {
    return (
      <div className="rbc-toolbar flex justify-between">
        <span className="rbc-btn-group md:mb-0 mb-3">
          <button
            type="button"
            onClick={() => onNavigate(Navigate.TODAY)}
            className=" border-0 bg-transparent text-xs font-semibold"
          >
            Today
          </button>
          <button
            type="button"
            onClick={() => onNavigate(Navigate.PREVIOUS)}
            className=" border-0 bg-transparent"
          >
            <img src={LeftIcon} alt="Previous" />
          </button>
          <span className="rbc-toolbar-label font-semibold md:text-base text-xs">
            {label}
          </span>
          <button
            type="button"
            onClick={() => onNavigate(Navigate.NEXT)}
            className=" border-0 bg-transparent"
          >
            <img src={RightIcon} alt="Next" />
          </button>
        </span>

        <span className="rbc-btn-group">
          <button
            type="button"
            className={
              view === 'month'
                ? 'rbc-active border-0 text-xs font-bold min-w-118'
                : ' border-0 text-xs font-bold min-w-118'
            }
            onClick={() => onView('month')}
          >
            Month
          </button>
          <button
            type="button"
            className={
              view === 'week'
                ? 'rbc-active border-0 text-xs font-bold min-w-118'
                : 'border-0 text-xs font-bold  border-l border-r border-purple-bdr min-w-118'
            }
            onClick={() => onView('week')}
          >
            Week
          </button>
          <button
            type="button"
            className={
              view === 'day'
                ? 'rbc-active border-0 text-xs font-bold min-w-118'
                : 'border-0 text-xs font-bold border-l border-purple-bdr min-w-118'
            }
            onClick={() => onView('day')}
          >
            Day
          </button>
        </span>
      </div>
    );
  };

  const formatToHoursMin = (value) => {
    const minutes = value % 60;
    const hours = Math.floor(value / 60);
    if (hours === 0) return `${minutes} min`;
    if (minutes === 0) return `${hours}h`;
    return `${hours}h ${minutes} min`;
  };

  // Define the formats for desktop devices
  const desktopFormats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, 'dddd', culture),

    dayHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, 'MMMM D, YYYY', culture),

    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'MMM DD', culture)} – ${localizer.format(
        end,
        'MMM DD',
        culture
      )}`,

    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'dddd DD', culture),

    dateFormat: (date, culture, localizer) =>
      localizer.format(date, 'D', culture),

    // Ensure that only the title is shown for day and week views
    eventTimeRangeFormat: () => null,
    eventTimeRangeStartFormat: () => null,
    eventTimeRangeEndFormat: () => null,
  };

  // Define the formats for mobile devices
  const mobileFormats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, 'ddd', culture),

    dayHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, 'MMM D', culture),

    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'MMM DD', culture)} – ${localizer.format(
        end,
        'MMM DD',
        culture
      )}`,

    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'ddd', culture),

    dateFormat: (date, culture, localizer) =>
      localizer.format(date, 'D', culture),

    // Ensure that only the title is shown for day and week views
    eventTimeRangeFormat: () => null,
    eventTimeRangeStartFormat: () => null,
    eventTimeRangeEndFormat: () => null,
  };

  // Function to determine the current device format
  const getFormats = () => {
    const isMobile = window.innerWidth <= 768; // or use any other breakpoint for mobile devices
    return isMobile ? mobileFormats : desktopFormats;
  };
  const isMobileDevice = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  useEffect(() => {
    const handleResize = () => setIsMobile(isMobileDevice());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const customTitle = (event) => {
    const startTime = moment(event.start).format('hh:mm A');
    const endTime = moment(event.end).format('hh:mm A');

    return (
      <div className="custom-event">
        <p className="event-details">{`${event.buyerName} - ${event.service}, ${startTime} - ${endTime}`}</p>
      </div>
    );
  };

  const customTooltip = (event) => {
    const startTime = moment(event.start).format('hh:mm A');
    const endTime = moment(event.end).format('hh:mm A');
    return `${event.buyerName} - ${event.service}, ${startTime} - ${endTime}`;
  };

  return (
    <>
      {(isLoading || loading) && <Spinner isBlock={false} />}
      <section className="pt-4 appointment-calender">
        <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultView="month"
            views={views}
            components={{
              toolbar: customToolbar,
            }}
            onNavigate={handleNavigate}
            onView={handleViewChange}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleEventClick}
            onSelectSlot={handleSelectSlot}
            // {...(!isMobile && { onSelectSlot: handleSelectSlot })}
            selectable
            onShowMore={onShowMore}
            // longPressThreshold={5}
            formats={getFormats()}
            titleAccessor={(event) => customTitle(event)}
            tooltipAccessor={(event) => customTooltip(event)}
            // Intercept the onDrillDown event to prevent changing the view
            onDrillDown={(date, view) => {
              if (view !== 'day') {
                setCurrentDate(date);
                setCurrentView(view);
              }
            }}
          />
          {/* Book An Appointment */}
          <ReactModal
            isOpen={isFirstModalOpen}
            onRequestClose={closeFirstModal}
            contentLabel="Example Modal"
            className={'md:w-120 w-5/6 bg-white-main'}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 31,
                backdropFilter: 'blur(2px)',
              },
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #ccc',
                backgroundColor: '#fff',

                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
              },
            }}
          >
            <div className="flex justify-between items-center md:mb-6 mb-1">
              <h2 className=" font-semibold md:text-2xl text-base">
                Book an appointment
              </h2>
              <span
                className="absolute right-4 top-2 font-semibold cursor-pointer"
                onClick={closeFirstModal}
              >
                &#x2715;
              </span>
            </div>
            <CalenderViewAppointmentForm
              activeDate={activeDate}
              closeFirstModal={closeFirstModal}
              setIsLoading={setIsLoading}
              refetch={refetch}
              openSecondModal={openSecondModal}
              newClientData={newClientData}
            />
          </ReactModal>
          {isSecondModalOpen && (
            <div className="modal-overlay">
              <div className="modal md:w-120">
                <div className="modal-content book-appointment">
                  <span
                    className="close absolute right-3 top-2 font-semibold cursor-pointer"
                    onClick={closeSecondModal}
                  >
                    &#x2715;
                  </span>
                  <AddClientForm
                    setIsLoading={setIsLoading}
                    closeAddNewClientModal={closeSecondModal}
                    refetch={refetch}
                    pageView="calendar-view"
                    openFirstModal={openFirstModal}
                    setNewClientData={setNewClientData}
                  />
                </div>
              </div>
            </div>
          )}
          {/* view event Details modal start here  */}
          {showEventPopup && (
            <div className="modal-overlay">
              <div className="modal md:w-150 max-h-80 overflow-y-auto">
                <div className="modal-content">
                  <span
                    className="close absolute right-3 top-2 font-semibold cursor-pointer"
                    onClick={() => setShowEventPopup(false)}
                  >
                    &#x2715;
                  </span>
                  <div className="flex items-center justify-between gap-12 pt-3">
                    <h2 className="font-semibold md:text-2xl text-sm">
                      Appointment Details{' '}
                    </h2>
                    <div className="font-semibold text-gray-middle md:text-base text-xs flex items-center gap-3">
                      <img src={DateIcon} alt="icon" />
                      <p>{clickedDate}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-2 mb-4">
                    <p className="md:text-base text-sm text-gray-dark">
                      Total Bookings :
                    </p>
                    <p className="text-base text-primary font-semibold">
                      {selectedDateEvents.length}
                    </p>
                  </div>
                  {selectedDateEvents.map((event, index) => (
                    <div className="grid mb-4" key={index}>
                      <div className=" grid grid-cols-3 gap-2 cal-data-shadow border border-gray-apBg px-3 py-3 rounded-lg">
                        <div>
                          <p className="md:text-sm text-xs font-medium text-gray-middle">
                            Name
                          </p>
                          <p className="md:text-sm text-xs text-gray-dark font-semibold">
                            {event.buyerName}
                          </p>
                          <div className="flex gap-3 justify-start items-center mt-3">
                            <div
                              className={`inline-flex rounded-full items-center py-0.5 whitespace-nowrap ${
                                event?.status === 'accepted'
                                  ? 'bg-green-lame'
                                  : event?.status === 'rejected' ||
                                    event?.status === 'canceled'
                                  ? 'bg-red-Verylight'
                                  : event?.status === 'completed'
                                  ? 'bg-gray-completed'
                                  : 'bg-blue-chipBg'
                              } px-3`}
                            >
                              <span
                                className={`text-xs capitalize ${
                                  event?.status === 'accepted' ||
                                  event?.status === 'completed'
                                    ? '.text-gray-middle'
                                    : event?.status === 'rejected' ||
                                      event?.status === 'canceled'
                                    ? 'text-red-drk'
                                    : 'text-primary'
                                } font-medium`}
                              >
                                {event?.status === 'accepted' ||
                                event?.status === 'completed'
                                  ? event?.status
                                  : event?.status === 'rejected' ||
                                    event?.status === 'canceled'
                                  ? event?.status
                                  : 'Rescheduled'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="md:text-sm text-xs font-medium text-gray-middle">
                            Service
                          </p>
                          <p className="md:text-sm text-xs text-gray-dark font-semibold">
                            {event.service}
                          </p>
                        </div>
                        <div>
                          <div className="flex gap-3 justify-start items-center">
                            <img src={TimeIcon} alt="icon" />
                            <p className="text-gray-middle font-medium md:text-sm text-xs">
                              {moment(event.start).format('hh:mm A')}
                            </p>
                          </div>
                          <div className="flex gap-3 justify-start items-start">
                            <img
                              src={ClockIcon}
                              alt="icon"
                              className=" mt-0.5"
                            />
                            <p className="text-gray-middle font-medium md:text-sm text-xs">
                              Duration :{' '}
                              {formatToHoursMin(event.durationInMinutes)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default MyCalendar;
