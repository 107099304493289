import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const addCLientFormSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'Please enter less than 50 letters')
    .required('Please enter client name')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),

  mobile: yup.string().required('Please enter mobile number'),
});

export const editClientFormSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'Please enter less than 50 letters')
    .required('Please enter client name')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
      'Please avoid special characters & leading space'
    ),

  mobile: yup
    .string()
    .required('Please enter mobile number')
    .test('phone', 'Please enter valid mobile number', (value) => {
      return isValidPhoneNumber(value || '');
    }),
});
