import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import {
  useCancelSubscription,
  useCreateSubscriptionCheckout,
  useGetCurrentStorefrontDetails,
  useGetSubscriptionPlan,
} from '../../../hooks';
import { showError, showSuccess } from '../../../utils';
import DollarModalIcon from '../../atoms/icons/dollar-modal.icon';
import { Spinner } from '../../atoms';
import { CancelConfirmationModal } from '../../atoms/subscription-cancel-confirmation/cancel-confirmation-modal';

const SubscriptionCheckout = ({ isSubscribed, isSessionCreated }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [cancelConfirmationModal, setCancelConfirmationModal] = useState(false); // State for modal visibility

  const { loading, error, data, refetch } = useGetSubscriptionPlan();
  const { cancelSellerSubscription, loading: cancelLoading } =
    useCancelSubscription();
  const { createSubscriptionCheckout, loading: checkoutLoading } =
    useCreateSubscriptionCheckout();

  useEffect(() => {
    if (data?.subscriptionPlan) {
      setSubscriptionPlan(data?.subscriptionPlan);
    }
  }, [data]);

  const sellerToken = localStorage.getItem('sellerToken');
  const { data: storefront, refetch: storeRefetch } =
    useGetCurrentStorefrontDetails();

  useEffect(() => {
    if (storefront?.currentStorefront) {
      // Convert object to string before storing in session storage
      const serializedData = JSON.stringify(storefront.currentStorefront);
      sessionStorage.setItem('storefrontData', serializedData);
    }
  }, [storefront]);

  useEffect(() => {
    refetch();
  }, [isSessionCreated]);

  const handleOpenCancelConfirmationModal = () => {
    setCancelConfirmationModal(true);
  };

  const handleCloseCancelConfirmationModal = () => {
    setCancelConfirmationModal(false);
  };

  const confirmationCallback = async (confirmed) => {
    if (confirmed) {
      try {
        const status = await cancelSellerSubscription(
          subscriptionPlan?.activeSubscription?.uid
        );
        console.log('Subscription cancel status', status);
        showSuccess('Subscription cancelled successfully');
        refetch();
        storeRefetch();
      } catch (error) {
        showError(error.message);
      } finally {
        handleCloseCancelConfirmationModal();
      }
    } else {
      handleCloseCancelConfirmationModal();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const stripe = await stripePromise;

      if (!stripe) {
        console.error('Stripe.js failed to load.');
        return;
      }

      const payload = {
        planPriceId: subscriptionPlan?.planPriceId,
        backLinks: {
          successBackUrl: `${window.location.origin}/payment-success`,
          cancelBackUrl: `${window.location.origin}/scheduler-dashboard`,
        },
      };

      const response = await createSubscriptionCheckout({ variables: payload });
      const sessionId =
        response?.data?.createSubscriptionCheckout?.remoteSession?.id;

      if (!sessionId) {
        console.error('Failed to create Stripe session.');
        return;
      }

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect to checkout failed:', error);
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
    }
  };

  const getNormalizeDate = (date) => {
    return moment(date, moment.ISO_8601).toDate();
  };
  return (
    <>
      {(loading || cancelLoading || checkoutLoading) && <Spinner />}
      <section className="grid place-content-center p-4 md:mt-5 mt-4">
        <div>
          <div className="border relative bg-white-main rounded-md shadow-block p-8 border-purple-bdr max-w-xl max-h-80 md:max-h-fit overflow-auto min-w-320">
            {subscriptionPlan?.activeSubscription &&
              subscriptionPlan?.activeSubscription?.currentPeriodEndedAt && (
                <>
                  <p className="absolute right-4 top-4 bg-green-cover px-4 py-1 text-white-main rounded-10 font-bold text-sm">
                    Valid till:{' '}
                    {moment(
                      subscriptionPlan?.activeSubscription?.currentPeriodEndedAt
                    ).format('MMMM D, YYYY')}
                  </p>
                </>
              )}
            <div className="flex justify-center mt-4 mb-4">
              <DollarModalIcon />
            </div>
            <h3 className="text-center text-3xl">{subscriptionPlan?.name}</h3>
            <h2 className="text-center font-bold text-3xl my-4 text-blue-hard">
              $
              {subscriptionPlan?.activeSubscription?.active
                ? subscriptionPlan?.activeSubscription?.subscriptionPlanPrice
                    ?.price
                : subscriptionPlan?.price}
            </h2>

            <ul className="html-content">
              {/* {console.log(
                'subscriptionPlan.description === ',
                subscriptionPlan.description
              )} */}
              {subscriptionPlan?.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: subscriptionPlan.description.replace(
                      /\n/g,
                      '<br/>'
                    ),
                  }}
                />
              )}
            </ul>
            <div className="flex gap-10 justify-center mt-4">
              {isSubscribed === false && (
                <button
                  className="w-48 rounded p-3 border-gray-dark border hover:bg-gray-hover text-xs font-bold"
                  onClick={handleOpenCancelConfirmationModal} // Open modal on click
                >
                  Cancel
                </button>
              )}
              {subscriptionPlan?.activeSubscription ? (
                !subscriptionPlan?.activeSubscription?.active ? (
                  <button
                    className="px-10 py-3 rounded bg-primary text-white-main hover:bg-blue-hover"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </button>
                ) : (
                  <button
                    className="px-6 py-1 rounded bg-red-cover text-white-main"
                    onClick={handleOpenCancelConfirmationModal} // Open modal on click
                    disabled={loading}
                  >
                    Cancel
                  </button>
                )
              ) : (
                <button
                  className="px-10 py-3 rounded bg-primary text-white-main hover:bg-blue-hover"
                  onClick={handleSubmit}
                >
                  Subscribe
                </button>
              )}
              {/* {subscriptionPlan?.activeSubscription?.active && ( */}

              {/* )} */}
            </div>
          </div>
        </div>
      </section>

      {/* Render CancelConfirmationModal with props */}
      <CancelConfirmationModal
        cancelConfirmationModal={cancelConfirmationModal}
        closeCancelConfirmationModal={handleCloseCancelConfirmationModal}
        confirmationCallback={confirmationCallback} // Pass confirmationCallback function
      />
    </>
  );
};

export default SubscriptionCheckout;
