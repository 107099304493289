import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

const GET_SERVICES = gql`
  query ListSellerServices(
    $pagination: PaginationInput
    $filter: SellerServicesFilterInput
  ) {
    sellerServices(pagination: $pagination, filter: $filter) {
      nodes {
        uid
        name
        status
        description
        durationInMinutes
        price
        appointmentsCount
        # createdAt
        # updatedAt
        category {
          name
          uid
          coverImage {
            url
            thumbUrl
          }
        }
        images {
          signedBlobId
          url
        }
      }
      pagination {
        perPage
        lastPage
        nextPage
        totalNodes
        totalPages
        currentPage
      }
      # draftSellerServicesCount
    }
  }
`;

export const useGetServices = (filter) => {
  const { loading, error, data, refetch } = useQuery(GET_SERVICES, {
    variables: {
      pagination: filter?.pagination,
      filter: {
        categoryUidIn: filter?.categoryUidIn,
        statusIn: filter?.statusIn,
        nameCont: filter?.nameCont,
      },
    },
  });

  return { loading, error, data, refetch };
};
