import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useGetWeeklySchedule,
  useRescheduleAppointment,
  useGetAvailableAppointmentSlots,
} from '../../../hooks';
import { appointmentRescheduleSchema } from '../../../constants';
import { showError, showSuccess } from '../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LeftIcon from '../../../assets/images/left-arw.png';
import RightIcon from '../../../assets/images/right-arw.png';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../atoms';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';

function convertToAbbreviation(fullDayName) {
  // Define an array mapping full day names to their abbreviated forms
  var daysMapping = {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
  };

  // Return the abbreviated form of the day if it exists in the mapping, otherwise return the input
  return daysMapping[fullDayName] || fullDayName;
}

// Define the order of days of the week
const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const sortArrayByDayName = (data) => {
  // Create a mapping of dayName to its index in the daysOfWeek array
  const dayIndexMap = {};
  daysOfWeek.forEach((day, index) => {
    dayIndexMap[day] = index;
  });

  // Sort the array using a custom comparator function
  data.sort((a, b) => {
    const dayIndexA = dayIndexMap[a.shiftDayName?.toLowerCase()];
    const dayIndexB = dayIndexMap[b.shiftDayName?.toLowerCase()];
    return dayIndexA - dayIndexB;
  });

  return data;
};

const formatTime = (isoTimestamp) => {
  if (!isoTimestamp) return '';
  if (!isoTimestamp?.includes('T')) return isoTimestamp;
  const date = new Date(isoTimestamp);
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  const timeValue = `0${formattedHours}:${minutes} ${ampm}`;
  return timeValue;
};

const convertTimeToISO8601DateTime = (time) => {
  if (!time) return '';
  if (time?.includes('T')) {
    return formatTime(time);
  }
  // Get the current date
  const currentDate = new Date();

  // Parse the input time in "HH:mm" format
  const [hours, minutes] = time?.split(':').map(Number);

  // Set the time to the current date
  currentDate.setHours(hours, minutes, 0, 0);

  // Convert the date object to an ISO 8601 DateTime format
  const iso8601DateTime = currentDate.toISOString();

  return formatTime(iso8601DateTime);
};

const convertToISO = (item) => {
  const data = {
    date: new Date(item?.date),
    time: item?.time,
  };

  const date = new Date();
  date.setHours(...item?.time.split(':'));
  const time = date.toISOString().slice(0, -5) + '+00:00';
  // Get ISO 8601 string for the date
  const isoStringTime = time?.split('T')[1];
  const isoDateString = data.date.toISOString().split('T')[0];

  // Combine date and time to form ISO 8601 string
  const isoString = `${isoDateString}T${isoStringTime}`;

  return isoString;
};

const formatDateOnly = (value) => {
  const date = new Date(value);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const getCurrentWeekCount = (date) => {
  const now = new Date(date);

  const yearStart = new Date(now.getFullYear(), 0, 1);

  let startDayOfWeek = yearStart.getDay();

  startDayOfWeek = startDayOfWeek === 0 ? 7 : startDayOfWeek;

  const diff =
    (now - yearStart + (startDayOfWeek - 1) * 24 * 60 * 60 * 1000) /
    (24 * 60 * 60 * 1000);

  const weekCount = Math.floor(diff / 7) + 1;

  return weekCount;
};
const displayDate = (dateString) => {
  var date = new Date(dateString);

  // Extract the month, day, and year
  var month = date.toLocaleString('en-US', { month: 'long' });
  var day = date.getDate();
  var year = date.getFullYear();

  // Format the date in the desired format
  var formattedDate = month + ' ' + day + ', ' + year;

  return formattedDate;
};

const getDateFormat = (dateString) => {
  // Create a Date object by parsing the date string
  var date = new Date(dateString);

  // Format the date in the desired format
  var formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  }).format(date);

  return formattedDate;
};

export const RescheduleAppointmentForm = ({
  setPage,
  selectedAppointment,
  setAppointmentData,
  closeRescheduleModal,
  setIsLoading,
  details,
  setAppointmentDetails,
  refetch,
  page,
  setFetchMoreAllowed,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    dateRange: {
      fromDate: '2024-04-28',
      toDate: '2024-05-05',
    },
  });
  const [bookedSlotDateTime, setBookedSlotDateTime] = useState(
    selectedAppointment?.startTime
  );
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const [scheduleData, setScheduleData] = useState([]);
  const { data: weeklyData, loading } = useGetWeeklySchedule(filter);
  const handleRescheduleAppointment = useRescheduleAppointment();

  const storedData = sessionStorage.getItem('storefrontData');
  const parsedData = storedData ? JSON.parse(storedData) : null;
  const subscriptionExpiryDate = new Date(parsedData?.subscriptionExpiryDate);
  const futureBookingsEnabled = parsedData?.futureBookingsEnabled;

  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const currentWeekNumber = getCurrentWeekCount(
    selectedAppointment?.startTime?.split('T')[0]
  );
  const [currentWeek, setCurrentWeek] = useState();
  const [availabelSlotsOption, setAvailabelSlotsOption] = useState([]);
  const {
    loading: slotsLoading,
    error,
    data: slotsData,
    refetch: refetchSlots,
  } = useGetAvailableAppointmentSlots({
    sellerServiceUid: selectedAppointment?.sellerService?.uid,
    shiftDate: selectedDate,
    appointmentUid: selectedAppointment?.uid,
  });

  useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus(), // Expose a focus function
  }));

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(appointmentRescheduleSchema),
    defaultValues: {
      time: '',
    },
  });

  const onSubmit = async (data) => {
    if (selectedDate === null) {
      return showError('Please select date');
    }

    setIsLoading(true);
    if (page !== 1) setPage?.(1);
    const payload = {
      appointmentUid: selectedAppointment?.uid,
      input: {
        // startTime: convertToISO({ date: selectedDate, time: data?.time }),
        startTime: data.time,
      },
    };
    const response = await handleRescheduleAppointment(payload);
    if (response?.data?.rescheduleAppointment) {
      if (details) {
        setAppointmentDetails(response?.data?.rescheduleAppointment);
        navigate(location.pathname, {
          replace: true,
          state: response?.data?.rescheduleAppointment,
        });
        showSuccess('Appointment rescheduled successfully');
        closeRescheduleModal();
        setIsLoading(false);
        reset();
        return;
      }
      setFetchMoreAllowed(false);
      setAppointmentData([]);
      closeRescheduleModal();
      refetch();
      showSuccess('Appointment rescheduled successfully');
      setIsLoading(false);
    } else {
      // Displaying the error message
      setIsLoading(false);
      showError(response);
    }
  };

  // Function to handle clicking on the left arrow
  const goToPreviousWeek = () => {
    if (currentWeek > 1) {
      setCurrentWeek(currentWeek - 1);
    }
  };

  // Function to handle clicking on the right arrow
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const expiryWeekCount = Math.ceil(
    (subscriptionExpiryDate - startOfYear) / (7 * 24 * 60 * 60 * 1000)
  );
  // Function to handle clicking on the right arrow
  const goToNextWeek = () => {
    if (futureBookingsEnabled) {
      setCurrentWeek(currentWeek + 1);
    } else if (currentWeek < expiryWeekCount) {
      setCurrentWeek(currentWeek + 1);
    }
  };

  useEffect(() => {
    setCurrentWeek(currentWeekNumber);
  }, [currentWeekNumber]);

  useEffect(() => {
    // formatWeekRange(currentWeek);
    if (currentWeek) {
      const startDate = new Date(`2024-01-01`);
      startDate.setDate(startDate.getDate() + (currentWeek - 1) * 7);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      setFilter({
        dateRange: {
          fromDate: formatDateOnly(startDate),
          toDate: formatDateOnly(endDate),
        },
      });
    }
  }, [currentWeek]);

  useEffect(() => {
    if (weeklyData) {
      const sortedData = sortArrayByDayName(weeklyData?.sellerScheduleStatuses);
      setScheduleData(
        sortedData.map((item) => {
          const newItem = {
            ...item,
          };

          if (!futureBookingsEnabled) {
            newItem.enabled =
              new Date(item.shiftDate) <= subscriptionExpiryDate;
          }

          return newItem;
        })
      );
    }
  }, [weeklyData, futureBookingsEnabled, subscriptionExpiryDate]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (selectedDate !== null) {
      refetchSlots();
    }
  }, [selectedDate, refetchSlots]);

  const isCurrentWeekInSchedule = () => {
    // Logic to check if currentDate falls within the currentWeek range
    const startDate = new Date(`2024-01-01`);
    startDate.setDate(startDate.getDate() + (currentWeek - 1) * 7);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    const formattedCurrentDate = format(new Date(), 'yyyy-MM-dd');
    return (
      formattedCurrentDate >= formatDateOnly(startDate) &&
      formattedCurrentDate <= formatDateOnly(endDate)
    );
  };

  useEffect(() => {
    const isCurrentDateEnabled = scheduleData.some(
      (item) => item.shiftDate === currentDate && item.enabled
    );
    if (selectedAppointment?.startTime) {
      return setSelectedDate(selectedAppointment?.startTime?.split('T')[0]);
    }
    if (isCurrentDateEnabled) {
      setSelectedDate(currentDate);
    } else {
      const nextEnabledDate = scheduleData.find(
        (item) => item.shiftDate > currentDate && item.enabled
      );
      if (nextEnabledDate) {
        setSelectedDate(nextEnabledDate.shiftDate);
      }
    }
  }, [currentDate, scheduleData, selectedAppointment]);

  const customSelectSearchInput = ({ ...props }) => {
    return (
      <input
        {...props}
        maxLength={10}
        style={{ width: '100%', maxWidth: '100%', padding: '2px 8px' }}
      /> // Set the input style
    );
  };

  useEffect(() => {
    if (slotsData?.availableAppointmentSlots?.avaliableTimeSlots) {
      const currentTime = new Date(); // Get the current time

      // Convert bookedSlotDateTime to the same format as available slots
      const formattedBookedSlot = new Date(bookedSlotDateTime);

      const filteredSlots =
        slotsData.availableAppointmentSlots.avaliableTimeSlots.filter(
          (slot) => {
            const slotTime = new Date(slot);
            // Filter out slots that are before the current time and not equal to booked slot
            return (
              slotTime > currentTime &&
              slotTime.getTime() !== formattedBookedSlot.getTime()
            );
          }
        );

      const options = filteredSlots.map((slot) => ({
        value: slot,
        label: new Date(slot).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true, // Use 12-hour format with AM/PM
        }),
      }));
      setAvailabelSlotsOption(options);
    }
  }, [slotsData, selectedDate, bookedSlotDateTime]);

  return (
    <>
      {(loading || slotsLoading) && <Spinner />}
      <form className="date-icon" onSubmit={handleSubmit(onSubmit)}>
        <div className="calander-box mt-8 md:w-full md:block">
          <div className="flex gap-4 justify-between items-center w-full">
            <p className="md:text-base text-xs font-semibold">
              {selectedDate ? displayDate(selectedDate) : 'Please Select Date'}
            </p>
            <div className="flex md:gap-4 gap-7">
              <img
                src={LeftIcon}
                alt="icon"
                className=" cursor-pointer"
                style={{ opacity: isCurrentWeekInSchedule() && 0.3 }}
                onClick={() => {
                  if (!isCurrentWeekInSchedule()) {
                    goToPreviousWeek();
                  }
                }}
              />
              <img
                src={RightIcon}
                alt="icon"
                className={`cursor-pointer ${
                  futureBookingsEnabled
                    ? ''
                    : currentWeek >= expiryWeekCount
                    ? 'opacity-40 cursor-not-allowed'
                    : ''
                }`}
                onClick={() => {
                  if (futureBookingsEnabled) {
                    goToNextWeek();
                  } else {
                    if (currentWeek >= expiryWeekCount) {
                      return;
                    } else {
                      goToNextWeek();
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="calendar-date flex md:gap-3 gap-1 flex-wrap items-center mt-3">
            {scheduleData?.map((item, index) => {
              const currentDate = format(new Date(), 'yyyy-MM-dd');
              const isFutureDate = item.shiftDate >= currentDate;
              return (
                <div
                  key={index}
                  onClick={() =>
                    isFutureDate &&
                    item?.enabled &&
                    setSelectedDate(item?.shiftDate)
                  }
                  className={`grid border border-purple-bdr rounded-xl md:p-2 p-1 py-2 text-center md:w-45 w-9 ${
                    selectedDate === item?.shiftDate && item?.enabled
                      ? 'border-blue-bar bg-blue-calBg cursor-pointer'
                      : !item?.enabled || !isFutureDate
                      ? 'opacity-40 cursor-not-allowed'
                      : 'hover:border-blue-bar hover:bg-blue-calBg cursor-pointer'
                  }`}
                >
                  <p className="text-purple-light text-xs">
                    {convertToAbbreviation(item?.shiftDayName)}
                  </p>
                  <p className="text-black-main font-semibold md:text-base text-sm">
                    {item?.shiftDate?.split('-')[2]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-4">
          <label className="text-sm  text-gray-dark">Time:</label>
          <Controller
            name="time"
            control={control}
            defaultValue=""
            render={({ field }) => {
              return (
                <ReactSelect
                  className="react-select-container text-sm w-full"
                  classNamePrefix="react-select"
                  options={availabelSlotsOption}
                  value={availabelSlotsOption.find(
                    (option) =>
                      option.label ===
                        convertTimeToISO8601DateTime(bookedSlotDateTime) ||
                      option.value === field?.value
                  )}
                  placeholder="Time"
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: '12px',
                    }),
                  }}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption.value);
                  }}
                  components={{ Input: customSelectSearchInput }} // Custom input component
                  isOptionDisabled={(option) => option.isDisabled} // Disable option if isDisabled is true
                />
              );
            }}
          />
          {errors && errors?.time && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.time?.message}
            </p>
          )}
        </div>

        <div className="grid mt-6">
          <button
            type="submit"
            className="h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
          >
            Book an appointment
          </button>
        </div>
      </form>
    </>
  );
};
