import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Listview from './list-view';
import MyCalendar from './calendar-view';

export const Appointments = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSelect = (tabIndex) => {
    setCurrentTab(tabIndex);
  };

  const [isFirstModalOpen, setFirstModalOpen] = useState(false);
  const openFirstModal = () => setFirstModalOpen(true);

  return (
    <>
      <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0">
        <div className="pl-3 pr-3 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div className="">
                <div className="hidden md:block">
                  <span className="text-xs text-gray-middle">Appointments</span>
                  <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                  <span className="text-xs text-gray-dark">
                    Todays Appointments
                  </span>
                </div>
                <h1 className="font-bold text-lg text-black text-left md:text-xl sm:text-sm lg:text-2xl truncate">
                  Appointments
                </h1>
              </div>
              {currentTab === 1 && (
                <button
                  className="block bg-primary text-white-main font-semibold text-sm py-1 px-3 rounded md:hidden"
                  onClick={openFirstModal}
                >
                  + Book an appointment
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="relative flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <Tabs
            className="mt-2"
            // selectedIndex={selectedTab}
            selectedIndex={currentTab}
            onSelect={handleTabSelect}
            // onSelect={(index) => setSelectedTab(index)}
          >
            <TabList className=" border-0 flex gap-3">
              <Tab className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto">
                <div
                  className={
                    currentTab === 0
                      ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary text-center'
                      : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle text-center'
                  }
                >
                  List View
                </div>
              </Tab>
              <Tab className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto">
                <div
                  className={
                    currentTab === 1
                      ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary text-center'
                      : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle text-center'
                  }
                >
                  Calendar View
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <Listview />
            </TabPanel>
            <TabPanel>
              <MyCalendar
                isFirstModalOpen={isFirstModalOpen}
                setFirstModalOpen={setFirstModalOpen}
                openFirstModal={openFirstModal}
              />
            </TabPanel>
          </Tabs>
        </section>
      </section>
    </>
  );
};
