import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useCreateSettings, useGetSettings } from '../../../hooks';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import PlusIcon from '../../../components/atoms/icons/plus-icon';
import { DeleteIcon } from '../../atoms';
import { manageSettingsSchema } from '../../../constants';
import dayjs from 'dayjs'; // Import dayjs
import Select from 'react-select';
import { showError } from '../../../utils';
import ResetIcon from '../../atoms/icons/clear-reset.icon';
import { ScheduleConfirmationModal } from '../../atoms/schedule-confirmation-modal/confirmation-modal';
import moment from 'moment';

const convertToProperCase = (dayName) => {
  // Convert the first letter to uppercase and the rest to lowercase
  return dayName.charAt(0).toUpperCase() + dayName.slice(1).toLowerCase();
};

const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

const convertTimeToISO8601DateTime = (time) => {
  if (!time) return '';

  // Get today's date
  const today = moment().format('YYYY-MM-DD');

  // Convert the time to 24-hour format
  const time24 = convertTo24HourFormat(time);

  // Combine date and time in local time zone and convert to ISO 8601 format
  const iso8601DateTime = moment(
    `${today} ${time24}`,
    'YYYY-MM-DD HH:mm'
  ).toISOString(true);

  return iso8601DateTime;
};

export const BusinessHoursForm = ({
  setScheduleUpdate,
  closeModal,
  scheduleUpdate,
  setIsLoading,
}) => {
  const handleCreateSettings = useCreateSettings();
  const { data, refetch, loading } = useGetSettings();
  const [modalData, setModalData] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const [checked, setChecked] = useState(false);
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeUpdateCount, setTimeUpdateCount] = useState(0);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(manageSettingsSchema),
    defaultValues: {
      days: [
        {
          dayName: 'monday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
        {
          dayName: 'tuesday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
        {
          dayName: 'wednesday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
        {
          dayName: 'thursday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
        {
          dayName: 'friday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
        {
          dayName: 'saturday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
        {
          dayName: 'sunday',
          enabled: false,
          time: [
            {
              endTime: '',
              startTime: '',
              indexId: 0,
            },
          ],
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'days',
  });

  const [error, setError] = useState('');

  const convertData = (data) => {
    let convertedData = [];

    data?.forEach((item) => {
      // Flatten the nested time array
      item.time.forEach((timeEntry) => {
        // Create a new object with the desired format
        const newItem = {
          dayName: item.dayName,
          enabled: item.enabled,
          endTime: convertTimeToISO8601DateTime(timeEntry.endTime),
          startTime: convertTimeToISO8601DateTime(timeEntry.startTime),
          // uid: timeEntry.uid,
        };

        // Add the new object to the converted data array
        convertedData.push(newItem);
      });
    });

    return convertedData;
  };

  const [scheduleConfirmationModal, setScheduleConfirmationModal] =
    useState(false);
  const [scheduleConfirmationModalData, setScheduleConfirmationModalData] =
    useState(false);

  // Define confirmation callback function
  const confirmationCallback = async (confirmed, formData) => {
    if (confirmed) {
      setIsLoading(true);
      // Proceed with form submission
      const convertedData = convertData(formData?.days);
      const payload = {
        input: convertedData,
      };

      const response = await handleCreateSettings(payload);
      if (response?.data?.setBusinessHoursSetting) {
        closeModal();
        setScheduleUpdate(!scheduleUpdate);
        reset();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showError(response);
      }
    } else {
      // Handle cancellation
      setIsLoading(false);
      closeScheduleConfirmationModal();
      closeModal();
    }
  };

  const onSubmit = async (formData) => {
    if (data && data?.businessHoursSettings?.nodes?.length > 0) {
      // If nodes is not empty, show the confirmation popup
      openScheduleConfirmationModal(formData); // Pass the form data to the confirmation modal
    } else {
      // If nodes is empty, skip the confirmation popup and proceed with form submission
      await confirmationCallback(true, formData);
    }
  };

  // Open confirmation modal function
  const openScheduleConfirmationModal = (formData) => {
    setScheduleConfirmationModalData(formData); // Store the form data in state
    setScheduleConfirmationModal(true);
  };

  // Close confirmation modal function
  const closeScheduleConfirmationModal = () => {
    setScheduleConfirmationModal(false);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      const mondaySchedule = fields.find((field) => field.dayName === 'monday');
      if (mondaySchedule) {
        const updatedFields = fields.map((field) => {
          if (field.dayName !== 'monday') {
            return { ...field, enabled: true, time: mondaySchedule.time };
          }
          return { ...field, enabled: true };
        });
        setValue('days', updatedFields);
      }
    } else {
      // If the checkbox is unchecked, you may want to revert all other days back to their original state.
      // Here, you would reset the enabled flag to false for all days except Monday.
      const updatedFields = fields.map((field) => {
        if (field.dayName !== 'monday') {
          return { ...field, enabled: false };
        }
        return field;
      });
      setValue('days', updatedFields);
    }
    setChecked(checked);
  };

  useEffect(() => {
    if (data && data?.businessHoursSettings?.nodes) {
      const transformedData = Object.values(
        data?.businessHoursSettings?.nodes.reduce((acc, obj) => {
          const { dayName, enabled, ...rest } = obj;
          if (!acc[dayName]) {
            acc[dayName] = { dayName, enabled, time: [] };
          }
          acc[dayName].time.push(rest);
          return acc;
        }, {})
      );
      // Format startTime and endTime to display only time
      transformedData.forEach((item) => {
        item.time.forEach((time) => {
          time.startTime = dayjs(time.startTime).format('hh:mm A');
          time.endTime = dayjs(time.endTime).format('hh:mm A');
        });
      });
      // Merge transformedData with fields based on dayName
      const mergedFields = fields.map((field) => {
        const match = transformedData.find(
          (data) => data.dayName === field.dayName
        );
        if (match) {
          return { ...field, time: match.time, enabled: match?.enabled };
        }
        return field;
      });

      const allEnabled = transformedData.every((item) => item.enabled === true);
      if (allEnabled) {
        setChecked(true);
      } else {
        setChecked(false);
      }

      // Check if Monday shift has no data, uncheck the checkbox
      const mondaySchedule = transformedData.find(
        (item) => item.dayName === 'monday'
      );
      if (
        !mondaySchedule ||
        (mondaySchedule && mondaySchedule.time.length === 0)
      ) {
        const updatedFields = mergedFields.map((field) => {
          if (field.dayName !== 'monday') {
            return { ...field, enabled: false };
          }
          return field;
        });
        setValue('days', updatedFields);
        setChecked(false);
      } else {
        setValue('days', mergedFields);
      }
    }
  }, [data]);

  const handleAddTimeField = (dayName, index) => {
    const newTimeField = { endTime: '', startTime: '', indexId: '' };
    const updatedFields = [...fields];
    const dayIndex = updatedFields.findIndex(
      (field) => field.dayName === dayName
    );
    if (dayIndex !== -1) {
      updatedFields[dayIndex].time.push(newTimeField);
      setValue('days', updatedFields);
    }
  };

  const handleDeleteTimeField = (dayName, timeIndex) => {
    const updatedFields = [...fields];
    const dayIndex = updatedFields.findIndex(
      (field) => field.dayName === dayName
    );
    if (dayIndex !== -1) {
      updatedFields[dayIndex].time.splice(timeIndex, 1); // Remove the time field
      setValue('days', updatedFields);
    }
  };

  useEffect(() => {
    const newTimeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour < 12 ? 'AM' : 'PM';
        const hourStr = hour12.toString().padStart(2, '0');
        const minuteStr = minute.toString().padStart(2, '0');
        const label = `${hourStr}:${minuteStr} ${period}`;
        const time = `${hourStr}:${minuteStr} ${period}`;
        const indexId = hour + 1;
        newTimeOptions.push({ value: time, label, indexId });
      }
    }
    setTimeOptions(newTimeOptions);
  }, []);

  const getSlotTimeOption = useCallback(
    (type, data, timeItem, timeIndex) => {
      let newTimeOptiosn = JSON.parse(JSON.stringify(timeOptions));

      // Remove the options from Dropdown which are already selected.
      data.time.forEach((existingTimeSlot, index) => {
        if (index === timeIndex) return; // If same time slot range, Don't limit/filter the range.

        const startTime = Date.parse(
          `01/01/2000 ${existingTimeSlot.startTime}`
        );
        const endTime = Date.parse(`01/01/2000 ${existingTimeSlot.endTime}`);

        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption < startTime || dropdownTimeOption > endTime;
        });
      });

      // Validate start time.
      if (type === 'startTime' && timeItem.endTime) {
        const currentEndTime = Date.parse(`01/01/2000 ${timeItem.endTime}`);
        // Start time Always must be less than end time if selected.
        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption < currentEndTime;
        });

        // and should be greater that nearest privious end time
        let timelist = JSON.parse(JSON.stringify(data.time));
        // remove the current index
        timelist.splice(timeIndex, 1);
        timelist = timelist.filter((item) => {
          return Date.parse(`01/01/2000 ${item.endTime}`) < currentEndTime;
        });
        let nearestEndTime = Date.parse(`01/01/2000 12:00 AM`);
        timelist.forEach((item) => {
          const currentEndTime = Date.parse(`01/01/2000 ${item.endTime}`);
          if (currentEndTime > nearestEndTime) {
            nearestEndTime = currentEndTime;
          }
        });

        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption >= nearestEndTime;
        });
      }

      // Validate end time
      if (type === 'endTime' && timeItem.startTime) {
        const currentStartTIme = Date.parse(`01/01/2000 ${timeItem.startTime}`);
        // end time Always must be greather start time and less that nearest next start time.
        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption > currentStartTIme;
        });

        // finding const nearestNextStartTime = '';
        let timelist = JSON.parse(JSON.stringify(data.time));
        // remove the current index
        timelist.splice(timeIndex, 1);
        // Time list which are greater than current start time
        timelist = timelist.filter((item) => {
          return Date.parse(`01/01/2000 ${item.endTime}`) > currentStartTIme;
        });

        let nearestNextStartTime = Date.parse(`01/01/2000 11:59 PM`);
        timelist.forEach((item) => {
          const currentStartTime = Date.parse(`01/01/2000 ${item.startTime}`);
          if (currentStartTime < nearestNextStartTime) {
            nearestNextStartTime = currentStartTime;
          }
        });

        newTimeOptiosn = newTimeOptiosn.filter((timeList) => {
          const dropdownTimeOption = Date.parse(`01/01/2000 ${timeList.value}`);
          return dropdownTimeOption < nearestNextStartTime;
        });
      }

      return newTimeOptiosn;
    },
    [timeOptions, timeUpdateCount]
  );

  const customSelectSearchInput = ({ ...props }) => {
    return (
      <input
        {...props}
        maxLength={10}
        style={{ width: 'auto', maxWidth: '70px', padding: '2px 5px' }}
      /> // Set the input style
    );
  };

  const handleClearField = (dayName, timeIndex) => {
    const updatedFields = [...fields];
    const dayIndex = updatedFields.findIndex(
      (field) => field.dayName === dayName
    );
    if (dayIndex !== -1 && updatedFields[dayIndex].time.length > 0) {
      updatedFields[dayIndex].time[0] = {
        endTime: '',
        startTime: '',
        indexId: '',
      };
      setValue('days', updatedFields);
    }
  };

  return (
    <>
      <form className="mt-4 coustom-lable" onSubmit={handleSubmit(onSubmit)}>
        {fields?.length && (
          <>
            {fields.map((data, index) => (
              <>
                <div
                  className="grid grid-cols-1 md:grid-cols-c4 md:gap-2  mt-4"
                  key={data.id}
                >
                  <Controller
                    name={`days.${index}.enabled`} // Unique name for checkbox within days array
                    control={control}
                    defaultChecked={data.enabled}
                    render={({ field }) => (
                      <div className="col-span-3 md:col-auto">
                        <FormControlLabel
                          className="text-base text-gray-dark"
                          control={
                            <Checkbox
                              {...field}
                              color="primary"
                              defaultChecked={data.enabled}
                            />
                          }
                          label={convertToProperCase(data.dayName)}
                        />
                      </div>
                    )}
                  />
                  <div className="w-full">
                    {data.time.map((timeItem, timeIndex) => (
                      <div
                        key={timeIndex}
                        className="grid grid-cols-gAuto md:gap-2 gap-2 mb-4"
                      >
                        <div>
                          <Controller
                            name={`days.${index}.time[${timeIndex}].startTime`} // Unique name for start time within days.time array
                            control={control}
                            defaultValue={timeItem.startTime}
                            render={({ field }) => (
                              <Select
                                className="react-select-container text-sm"
                                classNamePrefix="react-select"
                                options={getSlotTimeOption(
                                  'startTime',
                                  data,
                                  timeItem,
                                  timeIndex
                                )}
                                value={timeOptions.find(
                                  (option) =>
                                    option.value === timeItem.startTime
                                )}
                                onChange={(selectedOption) => {
                                  setTimeUpdateCount((c) => c + 1);
                                  field.onChange(selectedOption.value);
                                }}
                                placeholder="Start Time"
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    fontSize: '12px',
                                    margin: '0px',
                                  }),
                                }}
                                // Adjust the font size as needed
                                components={{ Input: customSelectSearchInput }} // Custom input component
                              />
                            )}
                          />
                          {errors?.days?.[index]?.time?.[timeIndex]
                            ?.startTime && (
                            <p className="text-red-drk text-r0.6875">
                              {
                                errors.days[index].time[timeIndex].startTime
                                  .message
                              }
                            </p>
                          )}
                        </div>
                        <div>
                          <Controller
                            name={`days.${index}.time[${timeIndex}].endTime`} // Unique name for end time within days.time array
                            control={control}
                            defaultValue={timeItem.endTime}
                            render={({ field }) => (
                              <Select
                                className="react-select-container text-sm"
                                classNamePrefix="react-select"
                                options={getSlotTimeOption(
                                  'endTime',
                                  data,
                                  timeItem,
                                  timeIndex
                                )}
                                value={timeOptions.find(
                                  (option) => option.value === timeItem.endTime
                                )}
                                onChange={(selectedOption) => {
                                  setTimeUpdateCount((c) => c + 1);
                                  field.onChange(selectedOption.value);
                                }}
                                placeholder="End Time"
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    fontSize: '12px',
                                    margin: '0px',
                                  }),
                                }} // Adjust the font size as needed
                                components={{ Input: customSelectSearchInput }} // Custom input component
                              />
                            )}
                          />
                          {errors?.days?.[index]?.time?.[timeIndex]
                            ?.endTime && (
                            <p className="text-red-drk text-r0.6875">
                              {
                                errors.days[index].time[timeIndex].endTime
                                  .message
                              }
                            </p>
                          )}
                        </div>
                        {timeIndex > 0 ? (
                          <div
                            className=" flex justify-center items-end pb-4 cursor-pointer"
                            onClick={() =>
                              handleDeleteTimeField(data?.dayName, timeIndex)
                            }
                          >
                            <DeleteIcon />
                          </div>
                        ) : (
                          <>
                            <div className="action-section flex gap-2">
                              <div
                                className=" flex justify-center items-end pb-4 cursor-pointer"
                                onClick={() =>
                                  handleAddTimeField(data?.dayName, index)
                                }
                              >
                                <PlusIcon />
                              </div>
                              <div
                                className=" flex justify-center items-end cursor-pointer"
                                onClick={() =>
                                  handleClearField(data?.dayName, 0)
                                }
                              >
                                <ResetIcon />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {index === 0 && (
                  <div className="flex bg-blue-light mt-4 px-1 py-0">
                    <FormControlLabel
                      className=" text-xs text-gray-dark"
                      control={
                        <Checkbox
                          color="primary"
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Copy these working hours to all days"
                    />
                  </div>
                )}
              </>
            ))}
          </>
        )}
        <button className=" w-full p-2 mt-4 rounded bg-primary text-white-main hover:bg-blue-hover">
          Save
        </button>
      </form>
      <ScheduleConfirmationModal
        apptCount=""
        scheduleConfirmationModal={scheduleConfirmationModal}
        closeScheduleConfirmationModal={closeScheduleConfirmationModal}
        confirmationCallback={
          (confirmed) =>
            confirmationCallback(confirmed, scheduleConfirmationModalData) // Pass the stored form data to the confirmation callback
        }
        actionType=""
      />
    </>
  );
};
