import React, { useEffect, useState } from 'react';
import { Archived } from '../../components/atoms/icons';
import { useGetBuyerContacts } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from './modal';
import BookAppointmentForm from '../../components/templates/book-appointment-form/book-appointment-form';
import { Spinner } from '../../components/atoms';

const ClientDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accountUid = location.state;
  const [buyerList, setBuyerList] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { loading, error, data, refetch } = useGetBuyerContacts(accountUid);

  useEffect(() => {
    if (data?.buyerContacts) {
      setBuyerList(data?.buyerContacts?.nodes);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [accountUid, refetch]);

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);

  const openAppointmentModal = () => {
    setIsAppointmentModalOpen(true);
  };

  const closeAppointmentModal = () => {
    setIsAppointmentModalOpen(false);
  };

  const handleClick = () => navigate(-1);
  const formatPhoneNumber = (phoneNumber) => {
    const match = phoneNumber?.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };
  return (
    <>
      {loading && <Spinner />}
      <div>
        <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0">
          <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
            <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
              <div className="flex justify-between items-center">
                <div>
                  <div className="hidden md:block">
                    <span
                      className="text-xs text-gray-middle cursor-pointer"
                      onClick={handleClick}
                    >
                      Clients
                    </span>
                    <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                    <span className="text-xs text-gray-dark">
                      Client Details
                    </span>
                  </div>
                  <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                    Client Details
                  </h1>
                </div>
                <div className="flex flex-row gap-x-3 md:h-7 lg:h-10">
                  <button
                    className=" px-2 pt-1 pb-1  ml-2  sm:ml-0 md:h-7 h-7 lg:pt-2 lg:pb-2 lg:h-10 whitespace-nowrap guide-add-buyer-button   border border-primary bg-primary  text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                    onClick={() => {
                      openAppointmentModal();
                    }}
                  >
                    <div className="flex items-center gap-2 w-full justify-center   text-14">
                      <span>+</span>
                      <span>Book An Appointment </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
          <section className="relative flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
            <div className="w-full overflow-y-auto mt-4">
              <div className="infinite-scroll-component__outerdiv">
                <div
                  className="infinite-scroll-component"
                  id="scrollable-container"
                  style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
                >
                  <table className="w-full main-table border-separate main-table">
                    <thead className="bg-gray-verylight sticky top-0 z-10">
                      <tr className="border-b border-gray-highlight ">
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left pl-4">
                          Client <span className=" md:inline hidden">Name</span>
                        </th>
                        <th className="text-r0.625 font-semibold text-gray-middle tracking-wider md:text-xs text-left md:table-cell hidden">
                          Phone Number
                        </th>
                        <th className="text-r0.625 px-4 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left md:table-cell hidden">
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {buyerList?.length ? (
                        buyerList?.map((item, index) => (
                          <>
                            <tr className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item  items-center lg1:mb-2">
                              <td className="font-semibold text-xs text-black-secondary py-4 pl-4">
                                <div className="grid gap-3">
                                  <span className="capitalize">
                                    {item?.formalName}
                                  </span>
                                  <span className="md:hidden table-cell truncate">
                                    {item?.email}
                                  </span>
                                  <span className="md:hidden table-cell">
                                    {formatPhoneNumber(item?.phone)}
                                  </span>
                                </div>
                              </td>
                              <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden">
                                {formatPhoneNumber(item?.phone)}
                              </td>
                              <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden truncate">
                                {item?.email}
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="h-50 w-full text-center">
                            <p className="font-semibold">No Client’s Found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      <Modal isOpen={isAppointmentModalOpen} onClose={closeAppointmentModal}>
        <h2 className="font-semibold md:text-2xl text-base text-gray-dark cursor-pointer">
          Book an appointment
        </h2>

        <BookAppointmentForm
          closeAppontmentModal={closeAppointmentModal}
          setIsLoading={setIsLoading}
          refetch={refetch}
        />
      </Modal>
    </>
  );
};

export default ClientDetails;
