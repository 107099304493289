import React, { useEffect, useState } from 'react';
import category from '../../assets/images/2.png';
import edit from '../../assets/images/create-outline.svg';
import trash from '../../assets/images/trash_icn.svg';
import trashDisabled from '../../assets/images/trash_icn_disabled.svg';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import examinationIcon from '../../assets/images/examinationIcon.svg';
import statusIcon from '../../assets/images/info.svg';
import crossIcon from '../../assets/images/cross.png';
import {
  useGetServices,
  usePublishServices,
  useDeleteServices,
  useGetCategories,
} from '../../hooks';
import AddService from './add-service';
import { showError, showSuccess } from '../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '../../components/atoms';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ArchivedIcon from '../../components/atoms/icons/archived.icon';

import Joyride, { STATUS, Step } from 'react-joyride';

const formatToHoursMin = (value) => {
  const minutes = value % 60;
  const hours = Math.floor(value / 60);
  if (hours === 0) return `${minutes} min`;
  if (minutes === 0) return `${hours}h`;
  return `${hours}h ${minutes} min`;
};

export const SchedulerServicePage = () => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const handlePublishServices = usePublishServices();
  const { data: categoriesData } = useGetCategories();
  const handleDeleteServices = useDeleteServices();
  const [serviceData, setServicesData] = useState([]);
  const [addService, setAddService] = useState(false);
  const [draftData, setDraftData] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [serviceArchiveModal, setServiceArchiveModal] = useState(false);
  const [editServiceData, setEditServiceData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [activeStatus, setActiveStatus] = useState([]);
  const [filterActive, setFilterActve] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    categoryUidIn: [],
    statusIn: [],
    nameCont: '',
    pagination: {
      page: 1,
      perPage: 15,
    },
  });

  const { data, refetch, loading } = useGetServices(filter);
  const navigate = useNavigate();

  const [showJoyride, setShowJoyride] = useState(true);
  useEffect(() => {
    // Check localStorage on component mount
    const joyrideCompleted = localStorage.getItem('joyride_services');
    if (joyrideCompleted === 'false') {
      setShowJoyride(false);
    }
  }, []);

  const goToAddService = () => {
    setEditServiceData(null);
    // setAddService(true);
    navigate('/add-service');
  };
  const goToServiceDetails = (data) => {
    // setAddService(true);
    navigate('/service-details', { state: data });
  };
  const [FilterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const [publishServiceModal, setPublishServiceModal] = useState(false);
  const FilterOpenModal = () => {
    setFilterModalIsOpen(true);
  };
  const FilterCloseModal = () => {
    setFilterModalIsOpen(false);
  };

  const PublishOpenModal = () => {
    setPublishServiceModal(true);
  };

  const PublishCloseModal = () => {
    setPublishServiceModal(false);
  };
  const categoryFilter = (item) => {
    if (activeCategories?.includes(item)) {
      setActiveCategories((prevCategories) =>
        prevCategories?.filter((category) => category !== item)
      );
    } else {
      setActiveCategories((prevCategories) => [...prevCategories, item]);
    }
  };

  const statusFilter = (item) => {
    if (activeStatus?.includes(item)) {
      setActiveStatus((prevStatus) =>
        prevStatus?.filter((category) => category !== item)
      );
    } else {
      setActiveStatus((prevStatus) => [...prevStatus, item]);
    }
  };

  const deleteService = async () => {
    setIsLoading(true);
    const payload = {
      sellerServiceUid: selectedData?.uid,
    };
    const response = await handleDeleteServices(payload);
    if (response) {
      setServicesData([]);
      setPage(1);
      showSuccess('Service archived successfully');
      closeConfirmationModal();
      closeServiceArchiveModal();
      refetch();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  const openConfirmationModal = (data) => {
    setSelectedData(data);
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const openServiceArchiveModal = (data) => {
    setSelectedData(data);
    setServiceArchiveModal(true);
  };

  const closeServiceArchiveModal = () => {
    setServiceArchiveModal(false);
  };

  const editServiceFunction = (data) => {
    setEditServiceData(data);
    // setAddService(true);
    navigate('/add-service', { state: data });
  };

  useEffect(() => {
    if (data && data?.sellerServices) {
      setServicesData((prevServices) => [
        ...prevServices,
        ...data?.sellerServices?.nodes,
      ]);

      const filteredData = data?.sellerServices?.nodes?.filter(
        (item) => item?.status === 'draft'
      );
      if (filteredData?.length) {
        setDraftData(filteredData);
      }
      if (data?.sellerServices?.pagination?.totalPages !== page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [data]);

  useEffect(() => {
    const filteredData = serviceData?.filter(
      (item) => item?.status === 'draft'
    );
    if (filteredData?.length) {
      setDraftData(filteredData);
    } else {
      setDraftData([]);
    }
  }, [serviceData]);

  const publishServiceFunction = async () => {
    setIsLoading(true);
    const response = await handlePublishServices();
    if (!response?.name) {
      setPage(1);
      setServicesData([]);
      showSuccess('Services published successfully');
      PublishCloseModal();
      refetch();
      setDraftData([]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  useEffect(() => {
    if (categoriesData && categoriesData?.categories) {
      setCategories(categoriesData?.categories?.nodes);
    }
  }, [categoriesData]);

  useEffect(() => {
    setFilter({
      categoryUidIn: activeCategories,
      statusIn: activeStatus,
      nameCont: typeof name === 'string' ? name : '',
      pagination: {
        page: page,
        perPage: 15,
      },
    });
    refetch();
  }, [page]);

  const clearAllFunction = () => {
    setActiveCategories([]);
    setActiveStatus([]);
  };

  const applyFilters = async (name) => {
    setServicesData([]);
    setPage(1);
    if (activeCategories?.length || activeStatus?.length) {
      setFilterActve(true);
    } else {
      setFilterActve(false);
    }
    setFilter({
      categoryUidIn: activeCategories,
      statusIn: activeStatus,
      nameCont: typeof name === 'string' ? name : '',
      pagination: {
        page: page,
        perPage: 15,
      },
    });
    await refetch();
    FilterCloseModal();
  };

  const fetchMoreData = () => {
    setPage(page + 1);
    setHasMore(false);
  };

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // Function to handle sorting
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    // Function to sort data based on current sorting state
    const sortedData = serviceData.slice().sort((a, b) => {
      if (sortColumn) {
        if (sortColumn.includes('.')) {
          const data = sortColumn?.split('.');
          const aValue = a[data[0]][data[1]].charAt(0).toLowerCase(); // Get the first letter and convert to lowercase
          const bValue = b[data[0]][data[1]].charAt(0).toLowerCase(); // Get the first letter and convert to lowercase
          if (sortOrder === 'asc') {
            return aValue.localeCompare(bValue); // Use localeCompare for string comparison
          } else {
            return bValue.localeCompare(aValue); // In descending order, reverse the comparison
          }
        }
        if (sortColumn === 'price') {
          const aValue = parseFloat(a.price); // Parse string to float
          const bValue = parseFloat(b.price); // Parse string to float

          if (!isNaN(aValue) && !isNaN(bValue)) {
            // Check if parsing was successful
            if (sortOrder === 'asc') {
              return aValue - bValue; // Ascending order
            } else {
              return bValue - aValue; // Descending order
            }
          } else if (isNaN(aValue)) {
            // Handle cases where parsing fails for aValue
            return 1; // aValue goes after bValue
          } else if (isNaN(bValue)) {
            // Handle cases where parsing fails for bValue
            return -1; // aValue goes before bValue
          } else {
            return 0; // Both are NaN, consider them equal
          }
        }
        const aValue = a[sortColumn].charAt(0).toLowerCase(); // Get the first letter and convert to lowercase
        const bValue = b[sortColumn].charAt(0).toLowerCase(); // Get the first letter and convert to lowercase
        if (sortOrder === 'asc') {
          return aValue.localeCompare(bValue); // Use localeCompare for string comparison
        } else {
          return bValue.localeCompare(aValue); // In descending order, reverse the comparison
        }
      }
      return 0;
    });
    setSortedData(sortedData);
  }, [serviceData, sortColumn, sortOrder]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const myInlineStyle = {
    width: '14px',
    height: '14px',
  };

  // add Joyride here
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
  };
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: (
          <div className="p-0 child-div">
            <p className="text-xs text-black-main text-left">
              Get started by creating new services tailored to your expertise
              and client needs. Click on the &quot;Add&quot; button to begin
              describe your unique offerings.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        title: 'Add New Services',
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 15,
        target: '.step-1',
        disableBeacon: true,
        disableScrolling: true, // Disable scrolling for this step
      },
      {
        content: (
          <p className="text-xs text-black-main text-left child-div">
            Click on the &quot;Edit&quot; button next to the predefined service
            you wish to modify. You will be redirected to the &quot;Edit
            Service&quot; page where you can update the service name,
            description, duration, pricing, and other relevant details.
          </p>
        ),
        target: '.step-2',
        title: 'Edit Predefined Service',
        floaterProps: {
          disableAnimation: true,
          placement: 'bottom', // Adjust placement as needed
          offset: { x: 0, y: 10 }, // Adjust offset as needed
        },
        spotlightPadding: 6,
        disableBeacon: true,
        disableScrolling: true, // Disable scrolling for this step
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      // Update localStorage when Joyride finishes
      localStorage.setItem('joyride_services', 'false');
      setShowJoyride(false); // Hide Joyride after completion
    }
  };

  useEffect(() => {
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('load', setVh);
      window.removeEventListener('resize', setVh);
    };
  }, []);
  return (
    <>
      {showJoyride && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      {(loading || isLoading) && <Spinner className="loader-cls" />}

      <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0">
        <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div>
                <div className="hidden md:block">
                  <span className="text-xs text-gray-middle">Service</span>
                  <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                  <span className="text-xs text-gray-dark">Services</span>
                </div>
                <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                  Services
                </h1>
              </div>

              <div className="flex  gap-2  md:gap-8">
                {/* <button
                  type="button"
                  onClick={PublishOpenModal}
                  disabled={!draftData?.length}
                  style={{ cursor: !draftData?.length && 'not-allowed' }}
                  className=" flex-none px-2 pt-1 pb-1  ml-2 sm:w-40 sm:px-0 sm:ml-0 md:h-7 lg:pt-2 lg:pb-2 lg:h-10  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
                >
                  Publish Services
                </button> */}
                <button
                  onClick={goToAddService}
                  type="submit"
                  className="step-1 flex-1 md:flex-none px-2 pt-1 pb-1  ml-2 sm:w-40 sm:px-0 sm:ml-0 md:h-7 lg:pt-2 lg:pb-2 lg:h-10 h-7 w-20 border border-primary bg-primary text-white-main  text-xs hover:bg-blue-hover md:py-2 py-1 text-center font-bold rounded-md"
                >
                  + Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="  py-2 border-b border-gray-light  md:py-5 md:flex-nowrap pl-2 pr-2 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="flex justify-end  flex-glow gap-2 md:gap-3">
            <div className="w-80 md:max-w-md">
              <div className="relative flex items-center rounded overflow-hidden border-gray-light border text-r1.125 hover:border-blue-hover hover:text-gray-middle">
                <div className="pr-2 absolute right-0">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0687 12.9807C10.5659 14.1098 8.67466 14.6424 6.78004 14.4702C4.88542 14.298 3.1299 13.4338 1.87093 12.0537C0.611964 10.6735 -0.0557828 8.8812 0.00365277 7.04162C0.0630884 5.20203 0.845238 3.45352 2.19084 2.1521C3.53645 0.850684 5.34433 0.0942198 7.24637 0.0367359C9.14842 -0.0207479 11.0016 0.625071 12.4286 1.84269C13.8556 3.06032 14.7491 4.75819 14.9272 6.59059C15.1052 8.423 14.5545 10.2522 13.3871 11.7056L17.7243 15.8847C18.0914 16.2385 18.092 16.8131 17.7255 17.1675C17.359 17.522 16.7645 17.5217 16.3984 17.1669L12.0781 12.9807H12.0687ZM7.48724 12.6913C8.22396 12.6913 8.95346 12.551 9.63409 12.2783C10.3147 12.0056 10.9332 11.606 11.4541 11.1021C11.975 10.5983 12.3883 10.0002 12.6702 9.34188C12.9521 8.6836 13.0972 7.97806 13.0972 7.26553C13.0972 6.55301 12.9521 5.84746 12.6702 5.18918C12.3883 4.5309 11.975 3.93276 11.4541 3.42893C10.9332 2.9251 10.3147 2.52545 9.63409 2.25277C8.95346 1.9801 8.22396 1.83976 7.48724 1.83976C5.99938 1.83976 4.57246 2.4114 3.52038 3.42893C2.4683 4.44646 1.87725 5.82653 1.87725 7.26553C1.87725 8.70454 2.4683 10.0846 3.52038 11.1021C4.57246 12.1197 5.99938 12.6913 7.48724 12.6913Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <input
                  className="pr-6 pl-2 w-full py-1.5 text-sm placeholder-gray-dark font-normal tracking-wide outline-none border-none"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => applyFilters(e?.target?.value)}
                />
              </div>
            </div>
            <button
              onClick={FilterOpenModal}
              type="button"
              className="group relative flex-1 md:flex-none w-40 border border-gray-border bg-white-main text-gray-dark text-xs hover:text-gray-middle hover:border-gray-light py-1.5 text-center font-bold rounded-md"
            >
              <div className="flex items-center gap-2 w-full justify-center">
                <svg
                  className="text-primary group-hover:text-blue-hover absolute md:left-3 left-2"
                  width="20"
                  height="11"
                  viewBox="0 0 20 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.45312 9.71118H12.2031M1.57812 0.961182H19.0781H1.57812ZM4.70312 5.33618H15.9531H4.70312Z"
                    stroke="currentColor"
                  ></path>
                </svg>
                <span className="hidden md:block">Filter</span>
                {filterActive ? <span className="red-dot block"></span> : <></>}
              </div>
            </button>
          </div>
          {/* {draftData?.length ? (
            <div className=" rounded-md border border-red-drk py-2 px-4 flex justify-center items-center bg-red-Verylight mt-4">
              <p className=" text-red-drk  font-medium text-xs">
                You have {draftData?.length} draft services ready to be
                published. Please click the &ldquo;Publish Services&ldquo;
                button.
              </p>
            </div>
          ) : (
            <></>
          )} */}
        </section>
        <section className="relative  pb-22 flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <div className="infinite-scroll-component__outerdiv">
            <div
              className="infinite-scroll-component"
              id="scrollable-container"
              style={{ height: 'calc(100vh - 256px)', overflow: 'auto' }}
            >
              <InfiniteScroll
                dataLength={serviceData?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                // loader={<h4>Loading...</h4>}
                // endMessage={<h4>Nothing more to show</h4>}
                scrollableTarget="scrollable-container"
                className="pb-12"
              >
                <table className="w-full main-table border-separate">
                  <thead className="bg-gray-verylight sticky top-0 z-10">
                    <tr className="border-b border-gray-highlight">
                      <th
                        className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs text-left max-w-1/4 cursor-pointer"
                        onClick={() => handleSort('name')}
                      >
                        Service{' '}
                        {sortColumn === 'name' && sortOrder === 'asc' && '▲'}
                        {sortColumn === 'name' && sortOrder === 'desc' && '▼'}
                      </th>
                      <th
                        className="text-r0.625 font-semibold px-3 text-gray-middle tracking-wider  md:text-xs text-left max-w-1/4  cursor-pointer"
                        onClick={() => handleSort('category.name')}
                      >
                        Category{' '}
                        {sortColumn === 'category.name' &&
                          sortOrder === 'asc' &&
                          '▲'}
                        {sortColumn === 'category.name' &&
                          sortOrder === 'desc' &&
                          '▼'}
                      </th>
                      <th
                        className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs text-left max-w-1/4 hidden  md:table-cell cursor-pointer"
                        onClick={() => handleSort('price')}
                      >
                        Price{' '}
                        {sortColumn === 'price' && sortOrder === 'asc' && '▲'}
                        {sortColumn === 'price' && sortOrder === 'desc' && '▼'}
                      </th>
                      <th className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs text-left md:max-w-1/4 inline-flex items-center gap-1">
                        <span>Status</span>
                        <img
                          className="block md:hidden"
                          style={myInlineStyle}
                          src={statusIcon}
                          alt="icon"
                          aria-describedby={id}
                          onClick={handleClick}
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                        >
                          <Typography sx={{ px: 2, py: 2 }}>
                            <button
                              className=" absolute right-3 top-4"
                              onClick={() => {
                                setAnchorEl(null);
                              }}
                            >
                              <img src={crossIcon} alt="close" />
                            </button>
                            <p className="text-sm font-semibold">
                              Service Status
                            </p>
                            <div className=" flex items-center gap-2 mt-4">
                              <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-blue-glow px-3">
                                <span className="draft mr-1"></span>
                                <span className="text-xs capitalize text-gray-middle font-medium">
                                  draft
                                </span>
                              </div>
                              <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-green-lame px-3">
                                <span className="published mr-1"></span>
                                <span className="text-xs capitalize text-green-darklime font-medium">
                                  published
                                </span>
                              </div>
                              <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-red-Verylight px-3">
                                <span className="deleted mr-1"></span>
                                <span className="text-xs capitalize text-red-drk font-medium">
                                  archived
                                </span>
                              </div>
                            </div>
                          </Typography>
                        </Popover>
                      </th>
                      <th className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs ">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData &&
                      !loading &&
                      sortedData?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item"
                          >
                            <td
                              className="py-2 "
                              onClick={() => goToServiceDetails(item)}
                            >
                              <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
                                <div className="h-12 w-12 p-1 rounded hidden md:block">
                                  <div className="aspect-w-1 aspect-h-1 text-xs">
                                    <img
                                      className="rounded"
                                      src={item?.images[0]?.url || category}
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                                <div className="grid py-2 px-1 md:py-0 md:px-0">
                                  <span className="font-semibold text-xs text-black-secondary">
                                    {item?.name}
                                  </span>
                                  <span className="text-xs">
                                    {formatToHoursMin(item?.durationInMinutes)}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="font-semibold px-3 text-xs text-black-secondary">
                              {item?.category?.name}
                            </td>
                            <td className="font-semibold text-xs text-black-secondary hidden md:table-cell">
                              ${item?.price?.toFixed(2)}
                            </td>
                            <td className="font-semibold text-xs text-black-secondary">
                              <div
                                className={
                                  item?.status === 'published'
                                    ? 'inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-green-lame md:px-3 px-1.5'
                                    : item?.status === 'draft'
                                    ? 'inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-blue-draft md:px-3 px-1.5'
                                    : 'inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-red-Verylight md:px-3 px-1.5'
                                }
                              >
                                <span
                                  className={
                                    item?.status === 'published'
                                      ? 'text-xs capitalize text-green-darklime font-medium md:block hidden'
                                      : item?.status === 'draft'
                                      ? 'text-xs capitalize text-gray-middle font-medium md:block hidden'
                                      : 'text-xs capitalize text-red-drk font-medium md:block hidden'
                                  }
                                >
                                  {item?.status === 'deleted'
                                    ? 'archived'
                                    : item?.status}
                                </span>
                                <span
                                  className={
                                    item?.status === 'published'
                                      ? 'text-xs capitalize text-green-darklime published font-medium block md:hidden'
                                      : item?.status === 'draft'
                                      ? 'text-xs capitalize text-gray-middle draft font-medium block md:hidden'
                                      : 'text-xs capitalize text-red-drk deleted font-medium block md:hidden'
                                  }
                                ></span>
                              </div>
                            </td>
                            <td className="text-right pl-1 md:pl-5 pr-3 md:pr-5 w-20 py-2">
                              <div className="flex justify-end md:justify-start gap-x-2 md:gap-x-4">
                                <div
                                  className={`flex guide-featured-brand  ${
                                    index === 0 ? 'step-2' : ''
                                  }`}
                                >
                                  <img
                                    className="min-w-r10"
                                    src={edit}
                                    alt="icon"
                                    onClick={() => editServiceFunction(item)}
                                  />
                                </div>
                                <div className="flex guide-featured-brand">
                                  <div
                                    style={{
                                      cursor:
                                        item?.status === 'deleted' &&
                                        'not-allowed',
                                      opacity:
                                        item?.status === 'deleted' && '0.4',
                                    }}
                                    onClick={() =>
                                      item?.appointmentsCount > 0
                                        ? openServiceArchiveModal(item)
                                        : item?.status !== 'deleted'
                                        ? openConfirmationModal(item)
                                        : null
                                    }
                                  >
                                    <ArchivedIcon />
                                  </div>
                                  {/* <img
                                    className="min-w-r10"
                                    src={
                                      item?.status === 'deleted'
                                        ? trashDisabled
                                        : trash
                                    }
                                    style={{
                                      cursor:
                                        item?.status === 'deleted' &&
                                        'not-allowed',
                                    }}
                                    alt="icon"
                                    onClick={() =>
                                      item?.status !== 'deleted' &&
                                      openConfirmationModal(item)
                                    }
                                  /> */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {!sortedData && !loading && (
                      <tr>
                        <td colSpan="5" className=" h-50 w-full text-center">
                          <p className="font-semibold">No Services Found</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </section>
      </section>
      <ReactModal
        isOpen={FilterModalIsOpen}
        onRequestClose={FilterCloseModal}
        className={'md:w-144 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className=" font-semibold md:text-2xl text-base">
            Filter Service
          </h2>
          <span
            className="absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={FilterCloseModal}
          >
            &#x2715;
          </span>
        </div>
        <h3 className="text-gray-dark md:text-base text-sm font-semibold">
          Categories
        </h3>
        <div className="flex flex-wrap gap-3 items-center mt-2 max-h-23 overflow-y-auto max-h-56">
          {categories?.length ? (
            categories?.map((data, index) => {
              return (
                <span
                  key={index}
                  onClick={() => categoryFilter(data?.uid)}
                  className={
                    activeCategories?.includes(data?.uid)
                      ? 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-blue-light border-blue-hover text-primary font-semibold'
                      : 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-white-main border-gray-dark text-black-secondary font-medium'
                  }
                >
                  {data?.name}
                </span>
              );
            })
          ) : (
            <>Kindly Add Categories first</>
          )}
        </div>
        <h3 className="text-gray-dark  md:text-base text-sm font-semibold mt-6">
          Status
        </h3>
        <div className="flex flex-wrap gap-3 items-center mt-2">
          <span
            onClick={() => statusFilter('published')}
            className={
              activeStatus?.includes('published')
                ? 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-blue-light border-blue-hover text-primary font-semibold'
                : 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-white-main border-gray-dark text-black-secondary font-medium'
            }
          >
            Published
          </span>
          <span
            onClick={() => statusFilter('draft')}
            className={
              activeStatus?.includes('draft')
                ? 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-blue-light border-blue-hover text-primary font-semibold'
                : 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-white-main border-gray-dark text-black-secondary font-medium'
            }
          >
            Draft
          </span>
          <span
            onClick={() => statusFilter('deleted')}
            className={
              activeStatus?.includes('deleted')
                ? 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-blue-light border-blue-hover text-primary font-semibold'
                : 'border rounded-10 px-4 py-1 cursor-pointer hover:text-primary hover:bg-blue-light hover:border-blue-hover hover:font-semibold duration-300  md:text-base text-xs  bg-white-main border-gray-dark text-black-secondary font-medium'
            }
          >
            Archived
          </span>
        </div>
        <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6">
          <button
            type="button"
            onClick={clearAllFunction}
            className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
          >
            Clear all
          </button>
          <button
            type="submit"
            onClick={applyFilters}
            className="flex-1 md:flex-none h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
          >
            Apply
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={publishServiceModal}
        onRequestClose={PublishCloseModal}
        className={'md:w-404 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <div className="flex justify-between items-center mb-6">
          <span
            className="absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={PublishCloseModal}
          >
            &#x2715;
          </span>
        </div>
        <div className="text-center mt-3">
          <p className="font-semibold">
            Are you sure want to publish <br />
            the services?
          </p>
        </div>

        <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6">
          <button
            type="button"
            onClick={PublishCloseModal}
            className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
          >
            No
          </button>
          <button
            type="submit"
            onClick={publishServiceFunction}
            className="flex-1 md:flex-none h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
          >
            Yes
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={confirmationModal}
        onRequestClose={closeConfirmationModal}
        className={'md:w-3/12 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <span
          className=" absolute right-4 top-2 font-semibold cursor-pointer"
          onClick={closeConfirmationModal}
        >
          &#x2715;
        </span>

        <div className="text-center mt-3 flex flex-col justify-center items-center">
          <img src={examinationIcon} />
          <p className="mt-3 font-semibold">
            Are you sure you want to archive service?
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4  mt-8">
          <button
            className="h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
            onClick={closeConfirmationModal}
          >
            Cancel
          </button>
          <button
            className="h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
            onClick={deleteService}
          >
            Archive
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={serviceArchiveModal}
        onRequestClose={closeServiceArchiveModal}
        className={'md:w-3/12 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <span
          className=" absolute right-4 top-2 font-semibold cursor-pointer"
          onClick={closeServiceArchiveModal}
        >
          &#x2715;
        </span>

        <div className="text-center mt-3 flex flex-col justify-center items-center">
          <img src={examinationIcon} />
          <p className="mt-3 font-semibold">
            The service you&apos;ve chosen has already been scheduled for a
            client booking. Are you sure you want to archive it?
          </p>
          <p className="mt-4 text-xs">
            <span className="font-semibold">Note:</span> If you&apos;ve
            scheduled an appointment for specific services and wish to remove
            it, the appointment will be automatically canceled.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-4  mt-8">
          <button
            className="h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
            onClick={closeServiceArchiveModal}
          >
            Cancel
          </button>
          <button
            className="h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
            onClick={deleteService}
          >
            Archive
          </button>
        </div>
      </ReactModal>
    </>
  );
};
