import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import image from '../../assets/images/categories.png';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { appointmentRejectionSchema } from '../../constants';
import { useRejectAppointment } from '../../hooks';
import Modal from 'react-modal';
import { showError, showSuccess } from '../../utils';
import { RescheduleAppointmentForm } from '../../components/templates';
import { ColoredSlug } from '../../components/atoms';
import { CopyButton } from '../../components/molecules';

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = `${date.toLocaleDateString('en-US', {
    month: 'long',
  })} ${date.getDate()}, ${date.getFullYear()}`;

  return formattedDate;
};

const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const match = phoneNumber.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  }
};

const formatToHoursMin = (value) => {
  const minutes = value % 60;
  const hours = Math.floor(value / 60);
  if (hours === 0) return `${minutes} min`;
  if (minutes === 0) return `${hours}h`;
  return `${hours}h ${minutes} min`;
};

const formatTime = (dateString) => {
  // Create a Date object by parsing the date string
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });
  return formattedTime;
};

const AppointmentDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleRejectAppointment = useRejectAppointment();
  const [RescheduleModal, setRescheduleModal] = useState(false);
  const [selectedAppointment, setselectedAppointment] = useState(null);
  const currentTime = new Date();
  const startTime = new Date(appointmentDetails?.startTime);
  const timeDifferenceInHours = (startTime - currentTime) / (1000 * 60 * 60);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(appointmentRejectionSchema),
  });

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      appointmentUid: selectedAppointment?.uid,
      ...data,
    };
    const response = await handleRejectAppointment(payload);
    if (response?.data?.rejectAppointment) {
      navigate(location.pathname, {
        replace: true,
        state: response?.data?.rejectAppointment,
      });
      showSuccess('Appointment canceled successfully');
      closeModal();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  const openModal = (value) => {
    reset();
    setselectedAppointment(value);
    setIsOpen(true);
  };
  const closeModal = () => {
    reset();
    setselectedAppointment(null);
    setIsOpen(false);
  };

  const openRescheduleModal = (value) => {
    setselectedAppointment(value);
    setRescheduleModal(true);
  };
  const closeRescheduleModal = () => {
    setselectedAppointment(null);
    setRescheduleModal(false);
  };

  useEffect(() => {
    if (location?.state) {
      setAppointmentDetails(location?.state);
    }
  }, [location]);

  return (
    <div>
      <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0 overflow-y-auto">
        <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div>
                <div className="hidden md:block">
                  <span className="text-xs text-gray-middle">Appointments</span>
                  <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                  <span className="text-xs text-gray-dark">
                    Appointment Details
                  </span>
                </div>
                <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                  Appointment Details
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="py-2   md:py-5 md:flex-nowrap pl-2 pr-2 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          {appointmentDetails?.status !== 'rejected' &&
            appointmentDetails?.status !== 'completed' &&
            appointmentDetails?.status !== 'canceled' && (
              <div className="flex gap-4  justify-end">
                {timeDifferenceInHours > 0 && (
                  <button
                    className=" bg-white-main py-2 px-5 rounded text-primary font-semibold text-xs"
                    onClick={() => openRescheduleModal(appointmentDetails)}
                  >
                    Reschedule
                  </button>
                )}
                <button
                  className=" bg-white-main py-2 px-5 rounded text-red-cover font-semibold text-xs"
                  onClick={() => openModal(appointmentDetails)}
                >
                  Cancel
                </button>
              </div>
            )}

          {appointmentDetails?.comment && (
            <div className=" rounded-md border border-red-drk py-2 px-4 flex justify-center items-center bg-red-Verylight mt-4">
              <p className=" text-red-drk  font-semibold text-sm">
                {appointmentDetails?.comment}
              </p>
            </div>
          )}
          <section className="mt-6 grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="text-gray-dark cursor-pointer  hover:bg-gray-lightHover bg-white-main shadow-items gap-4 p-4 rounded bottom-1 border-gray-light w-full mb-4">
              {/* <div className="md:flex grid gap-4 justify-between items-center w-full border-b border-gray-light pb-4 mb-4">
                <h2 className="text-xs font-semibold text-black-secondary break-all flex">
                  Appointment ID:&nbsp;
                  <span className="truncate w-150 md:w-200">
                    {appointmentDetails?.uid}{' '}
                  </span>
                  <CopyButton entityId={appointmentDetails?.uid} />
                </h2>
              </div> */}
              <div className="mb-7">
                <p className="text-xs font-medium text-black-secondary">
                  Service Name: {appointmentDetails?.sellerService?.name}
                </p>
                <div className="text-right">
                  {appointmentDetails?.status === 'accepted' ? (
                    <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-green-lame px-3">
                      <span className="text-xs capitalize text-green-darklime font-medium">
                        {appointmentDetails?.status}
                      </span>
                    </div>
                  ) : appointmentDetails?.status === 'completed' ? (
                    <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-gray-completed  px-3">
                      <span className="text-xs capitalize text-gray-middle font-medium">
                        {appointmentDetails?.status}
                      </span>
                    </div>
                  ) : appointmentDetails?.status === 'rejected' ||
                    appointmentDetails?.status === 'canceled' ? (
                    <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-red-Verylight px-3">
                      <span className="text-xs capitalize text-red-drk font-medium">
                        canceled
                      </span>
                    </div>
                  ) : (
                    <div className="inline-flex rounded-full items-center py-0.5 whitespace-nowrap bg-blue-chipBg px-3">
                      <span className="text-xs capitalize text-primary font-medium">
                        Rescheduled
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid gap-4 grid-cols-3">
                <div>
                  <p className="text-xs font-medium text-black-secondary">
                    Date
                  </p>
                  <p className="font-semibold text-xs text-black-secondary">
                    {formatDate(appointmentDetails?.startTime)}
                  </p>
                </div>
                <div>
                  <p className="text-xs font-medium text-black-secondary">
                    Time
                  </p>
                  <p className="font-semibold text-xs text-black-secondary">
                    {formatTime(appointmentDetails?.startTime)}
                  </p>
                </div>
                <div>
                  <p className="text-xs font-medium text-black-secondary">
                    Duration
                  </p>
                  <p className="font-semibold text-xs text-black-secondary">
                    {formatToHoursMin(
                      appointmentDetails?.sellerService?.durationInMinutes
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-gray-dark cursor-pointer  hover:bg-gray-lightHover bg-white-main shadow-items gap-4 p-4 rounded bottom-1 border-gray-light w-full mb-4">
              <h2 className="text-xs font-semibold text-black-secondary">
                Customer Details
              </h2>
              <div className="mt-4">
                <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
                  <div className="h-12 w-12 p-1 rounded-lg">
                    <div className="aspect-w-1 aspect-h-1 rounded-lg">
                      {/* <img className="rounded-lg" src={image} alt="icon" /> */}
                      <ColoredSlug
                        name={appointmentDetails?.buyer?.formalName}
                      />
                    </div>
                  </div>
                  <span className="font-semibold text-black-secondary text-xs capitalize">
                    {appointmentDetails?.buyer?.formalName}
                  </span>
                </div>
              </div>
              <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mt-4 ">
                <div>
                  <p className="text-xs font-medium text-black-secondary">
                    Email
                  </p>
                  <p className="font-semibold text-xs text-black-secondary break-words">
                    {appointmentDetails?.buyer?.email}
                  </p>
                </div>
                <div>
                  <p className="text-xs font-medium text-black-secondary">
                    Phone
                  </p>
                  <p className="font-semibold text-xs text-black-secondary">
                    {formatPhoneNumber(appointmentDetails?.buyer?.phone)}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            className={'md:w-120 w-5/6 bg-white-main'}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 31,
                backdropFilter: 'blur(2px)',
              },
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #ccc',
                backgroundColor: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
              },
            }}
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className=" font-semibold md:text-2xl text-base">
                Cancel Appointment
              </h2>
              <span
                className="absolute right-4 top-2 font-semibold cursor-pointer"
                onClick={closeModal}
              >
                &#x2715;
              </span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid w-full relative">
                <textarea
                  rows="4"
                  placeholder="Add reason"
                  className="w-full text-xs font-medium rounded border border-purple-bdr console-input p-2"
                  // name="Add reason"
                  {...register('comment')}
                  onInput={(event) => {
                    const maxLength = 200;
                    const value = event.target.value;
                    if (value.length > maxLength) {
                      event.target.value = value.slice(0, maxLength);
                    }
                  }}
                />
                {errors && errors?.comment && (
                  <p className="text-red-drk md:text-r0.6875 text-r0.625 top-20 absolute">
                    {errors?.comment?.message}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6">
                <button
                  onClick={closeModal}
                  type="button"
                  className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex-1 md:flex-none h-10 w-full border bg-red-cover text-white-main  text-xs hover:bg-red-danger-hover py-2 text-center font-bold rounded-md"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
          <Modal
            isOpen={RescheduleModal}
            onRequestClose={closeRescheduleModal}
            contentLabel="Example Modal"
            className={'md:w-120 w-5/6 bg-white-main'}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 31,
                backdropFilter: 'blur(2px)',
              },
              content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '1px solid #ccc',
                backgroundColor: '#fff',

                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
              },
            }}
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className=" font-semibold md:text-2xl text-base">
                Reschedule Appointment
              </h2>
              <span
                className="absolute right-4 top-2 font-semibold cursor-pointer"
                onClick={closeRescheduleModal}
              >
                &#x2715;
              </span>
            </div>
            <RescheduleAppointmentForm
              selectedAppointment={selectedAppointment}
              closeRescheduleModal={closeRescheduleModal}
              setIsLoading={setIsLoading}
              details={true}
              setAppointmentDetails={setAppointmentDetails}
            />
          </Modal>
        </section>
      </section>
    </div>
  );
};

export default AppointmentDetails;
