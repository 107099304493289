import React, { useEffect, useState } from 'react';
import ArchivedIcon from '../../components/atoms/icons/archived.icon';
import { Archived } from '../../components/atoms/icons';
import { useGetClients, useUnArchiveClient } from '../../hooks';
import { showSuccess } from '../../utils';
import ReactModal from 'react-modal';

const formatPhoneNumber = (phoneNumber) => {
  const match = phoneNumber.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

const ArchivedList = ({ setIsLoading, searchedText }) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [restoreClientModal, setRestoreClientModal] = useState(false);
  const [clientId, setClientId] = useState(null);
  const handleUnArchiveClient = useUnArchiveClient();
  const [filter, setFilter] = useState({
    filter: {
      nameMatches: '',
    },
    pagination: {
      page: 1,
      perPage: 15,
    },
    archived: true,
  });
  const { data, refetch, loading } = useGetClients(filter);

  const fetchMoreData = () => {
    setPage(page + 1);
    setHasMore(false);
  };

  const RestoreClientCloseModal = () => {
    setRestoreClientModal(false);
  };
  const RestoreClientOpenModal = () => {
    setRestoreClientModal(true);
  };

  useEffect(() => {
    if (data?.buyers) {
      setClientList((prevClients) => [...prevClients, ...data?.buyers?.nodes]);
      if (data?.buyers?.pagination?.totalPages !== page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (searchedText) {
      setClientList([]);
    }
    setFilter({
      filter: {
        nameMatches: searchedText,
      },
      pagination: {
        page: page,
        perPage: 15,
      },
      archived: true,
    });
  }, [page, searchedText]);

  const handleUnArchiveClientFunc = async () => {
    setIsLoading(true);
    const payload = {
      accountUid: clientId,
    };
    const response = await handleUnArchiveClient(payload);
    if (response?.data?.unarchiveBuyer?.status === 'OK') {
      setPage(1);
      setClientList([]);
      setClientId(null);
      RestoreClientCloseModal();
      refetch();
      setIsLoading(false);
      showSuccess('Client unarchived successfully');
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="w-full overflow-y-auto pb-8 max-height-62 mt-4">
        <table className="w-full main-table border-separate  main-table">
          <thead className="bg-gray-verylight sticky top-0 z-10">
            <tr className="border-b border-gray-highlight lg1:grid lg1:grid-cols-clientTable">
              <th className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs text-left  pl-4">
                Client <span className="md:inline hidden">Name</span>
              </th>
              <th className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs text-left md:table-cell hidden">
                Phone Number
              </th>
              <th className="text-r0.625 px-4 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left  md:table-cell hidden">
                Email
              </th>
              <th className="text-r0.625 font-semibold text-gray-middle tracking-wider  md:text-xs text-left">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {clientList?.length ? (
              clientList?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item lg1:grid lg1:grid-cols-clientTable items-center lg1:mb-2"
                  >
                    <td className="font-semibold text-xs text-black-secondary py-4 pl-4">
                      <div className="grid gap-3">
                        <span className="capitalize">{item?.formalName}</span>
                        <span className="md:hidden table-cell">
                          {item?.email}
                        </span>
                        <span className="md:hidden table-cell">
                          {' '}
                          {formatPhoneNumber(item?.phone)}
                        </span>
                      </div>
                    </td>
                    <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden">
                      {formatPhoneNumber(item?.phone)}
                    </td>
                    <td className="font-semibold text-xs text-black-secondary py-4 md:table-cell hidden truncate">
                      {item?.email}
                    </td>

                    <td
                      className="font-semibold text-xs text-black-secondary py-4"
                      onClick={() => {
                        RestoreClientOpenModal();
                        setClientId(item?.uid);
                      }}
                    >
                      <Archived />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className=" h-50 w-full text-center">
                  <p className="font-semibold">No Archived Client’s Found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ReactModal
        isOpen={restoreClientModal}
        onRequestClose={RestoreClientCloseModal}
        className={'md:w-404 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 31,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
      >
        <div className="flex justify-between items-center mb-6">
          <span
            className="absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={RestoreClientCloseModal}
          >
            &#x2715;
          </span>
        </div>
        <div className="text-center mt-3">
          <p className="font-semibold">
            Are you sure you want to move archived <br />
            client to client list?
          </p>
        </div>

        <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6">
          <button
            type="button"
            onClick={RestoreClientCloseModal}
            className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
          >
            No
          </button>
          <button
            type="submit"
            onClick={handleUnArchiveClientFunc}
            className="flex-1 md:flex-none h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
          >
            Yes
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default ArchivedList;
