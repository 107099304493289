import * as yup from 'yup';

export const manageSettingsSchema = yup.object().shape({
  days: yup.array().of(
    yup.object().shape({
      time: yup.array().of(
        yup.object().shape({
          startTime: yup
            .string()
            .nullable()
            .required('Start time is required')
            .test(
              'is-before-end',
              'Start time must be less than end time',
              function (startTime) {
                const { endTime } = this.parent; // Get the value of endTime
                if (!startTime || !endTime) return true; // If either value is missing, let other validations handle it

                // Convert times to minutes for comparison
                const startMinutes = convertToMinutes(startTime);
                const endMinutes = convertToMinutes(endTime);

                return startMinutes < endMinutes; // Compare startTime and endTime
              }
            ),
          endTime: yup
            .string()
            .nullable()
            .required('End time is required')
            .test(
              'is-after-start',
              'End time must be greater than start time',
              function (endTime) {
                const { startTime } = this.parent; // Get the value of startTime
                if (!startTime || !endTime) return true; // If either value is missing, let other validations handle it

                // Convert times to minutes for comparison
                const startMinutes = convertToMinutes(startTime);
                const endMinutes = convertToMinutes(endTime);

                return startMinutes < endMinutes; // Compare startTime and endTime
              }
            ),
        })
      ),
    })
  ),
});

// Function to convert time to minutes
const convertToMinutes = (time) => {
  const [hour, minute, period] = time.split(/[:\s]/);
  let hourValue = parseInt(hour);
  if (period === 'PM' && hour !== '12') {
    hourValue += 12;
  } else if (period === 'AM' && hour === '12') {
    hourValue = 0;
  }
  return hourValue * 60 + parseInt(minute);
};
