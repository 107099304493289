import * as yup from 'yup';

// Define your validation schema function
export const calendarViewAppointmentBookingSchema = () => {
  return yup
    .object()
    .shape({
      client: yup.string().required('Please select a client.'),
      contact: yup.string().required('Please select a contact.'),
      service: yup.string().required('Please select a service.'),
      time: yup.string().required('Please select a time.'),
      emailNotify: yup.boolean(),
      mobileNotify: yup.boolean(),
    })
    .test(
      'notificationMethod',
      'Please select at least one notification method.',
      (values) => {
        if (!values.emailNotify && !values.mobileNotify) {
          return false; // Validation fails if neither email nor mobile notify is selected
        }
        return true; // Validation passes if at least one notification method is selected
      }
    );
};
