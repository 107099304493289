import React, { useEffect, useState } from 'react';
import { useCreateSession, useGetCurrentStorefrontDetails } from '../../hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dashboard } from '../dashboard';
import SchedulerPage from '../scheduler-dashboar/scheduler.page';

export const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubscribed, setIsSubscribed] = useState(
    JSON.parse(localStorage.getItem('isSubscribed')) || false
  );
  const [isSessionCreated, setIsSessionCreated] = useState(false);
  const handleCreateSession = useCreateSession();

  const createSessionFunction = async (token) => {
    const payload = { token };
    localStorage.clear();
    const response = await handleCreateSession(payload);
    if (response?.data?.createSessionWithToken) {
      localStorage.setItem(
        'sellerToken',
        response?.data?.createSessionWithToken?.token
      );
      localStorage.setItem(
        'marketPlace',
        response?.data?.createSessionWithToken?.marketplaceUid
      );
      localStorage.setItem(
        'isSubscribed',
        JSON.stringify(response?.data?.createSessionWithToken?.isSubscribed)
      );
      setIsSubscribed(response?.data?.createSessionWithToken?.isSubscribed);
      setIsSessionCreated(true);
    }
    navigate(location.pathname, { replace: true });
  };

  useEffect(() => {
    if (location?.search) {
      const userToken = new URLSearchParams(location.search).get('token');
      if (userToken) {
        localStorage.setItem('userToken', userToken);
        createSessionFunction(userToken);
      }
    } else {
      // Initialize isSubscribed from localStorage if it exists
      const subscribed = JSON.parse(localStorage.getItem('isSubscribed'));
      if (subscribed !== null) {
        setIsSubscribed(subscribed);
      }
    }
  }, [location?.search]);

  return (
    <>
      {isSubscribed ? (
        <Dashboard />
      ) : (
        <SchedulerPage
          isSubscribed={isSubscribed}
          isSessionCreated={isSessionCreated}
        />
      )}
    </>
  );
};
