import * as yup from 'yup';

export const editFormSchema = yup.object().shape({
  shifts: yup.array().of(
    yup.object().shape({
      startTime: yup.string().required('Start time is required'),
      // .test(
      //   'is-before-end',
      //   'Start time must be less than end time',
      //   function (startTime) {
      //     const { endTime } = this.parent; // Get the value of endTime
      //     if (!startTime || !endTime) return true; // If either value is missing, let other validations handle it

      //     // Convert times to minutes for comparison
      //     const startMinutes = convertToMinutes(startTime);
      //     const endMinutes = convertToMinutes(endTime);

      //     return startMinutes < endMinutes; // Compare startTime and endTime
      //   }
      // )
      // .test(
      //   'is-valid-time',
      //   'Please select time in 5-minute intervals',
      //   function (startTime) {
      //     if (!startTime) return true; // Return true if startTime is empty
      //     const isValid = isValidTime(startTime);
      //     return isValid; // Return true if startTime is valid
      //   }
      // ),
      endTime: yup.string().required('End time is required'),
      // .test(
      //   'is-after-start',
      //   'End time must be greater than start time',
      //   function (endTime) {
      //     const { startTime } = this.parent; // Get the value of startTime
      //     if (!startTime || !endTime) return true; // If either value is missing, let other validations handle it

      //     // Convert times to minutes for comparison
      //     const startMinutes = convertToMinutes(startTime);
      //     const endMinutes = convertToMinutes(endTime);

      //     return startMinutes < endMinutes; // Compare startTime and endTime
      //   }
      // )
      // .test(
      //   'is-valid-time',
      //   'Please select time in 5-minute intervals',
      //   function (endTime) {
      //     if (!endTime) return true; // Return true if endTime is empty
      //     const isValid = isValidTime(endTime);
      //     return isValid; // Return true if endTime is valid
      //   }
      // ),
    })
  ),
});

// Function to check if the selected time is valid (in 5-minute intervals)
const isValidTime = (time) => {
  let [hour, minute, period] = time.split(/[:\s]/);
  if (period === 'PM' && hour !== '12') {
    hour = parseInt(hour) + 12;
  }
  const totalMinutes = parseInt(hour) * 60 + parseInt(minute);
  return totalMinutes % 5 === 0;
};

// Function to convert time to minutes
const convertToMinutes = (time) => {
  const [hour, minute, period] = time.split(/[:\s]/);
  let hourValue = parseInt(hour);
  if (period === 'PM' && hour !== '12') {
    hourValue += 12;
  } else if (period === 'AM' && hour === '12') {
    hourValue = 0;
  }
  return hourValue * 60 + parseInt(minute);
};
