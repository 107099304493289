import React, { useEffect, useState } from 'react';
import category from '../../../assets/images/categories.png';
import leftIcon from '../../../assets/images/left-arw.png';
import rightIcon from '../../../assets/images/right-arw.png';
import EditIcon from '../icons/editIcon';
import ReactModal from 'react-modal';
import { useGetBusinessHours } from '../../../hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EditForm } from './edit-form';
import { Spinner } from '../../atoms';

// Define the order of days of the week
const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const getCurrentWeekCount = () => {
  const now = new Date();
  const yearStart = new Date(now.getFullYear(), 0, 0);
  const diff = now - yearStart;
  const oneWeek = 7 * 24 * 60 * 60 * 1000;
  const weekCount = Math.floor(diff / oneWeek);
  return weekCount;
};

// Function to sort an array based on dayName from Monday to Sunday
const sortArrayByDayName = (data) => {
  // Create a mapping of dayName to its index in the daysOfWeek array
  const dayIndexMap = {};
  daysOfWeek.forEach((day, index) => {
    dayIndexMap[day] = index;
  });

  // Sort the array using a custom comparator function
  data.sort((a, b) => {
    const dayIndexA = dayIndexMap[a.shiftDayName?.toLowerCase()];
    const dayIndexB = dayIndexMap[b.shiftDayName?.toLowerCase()];
    return dayIndexA - dayIndexB;
  });

  return data;
};

const convertToProperCase = (dayName) => {
  // Convert the first letter to uppercase and the rest to lowercase
  return dayName.charAt(0).toUpperCase() + dayName.slice(1).toLowerCase();
};

// Extend dayjs with UTC plugin
dayjs.extend(utc);

// const formatDate = (dateString) => {
//   // Parse the date string into a JavaScript Date object
//   const date = new Date(dateString);

//   // Use dayjs to format the date
//   const formattedDate = dayjs(date).format('DD, MMM');

//   return formattedDate;
// };

const formatDate = (inputDate) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const dateObj = new Date(inputDate);
  const dayOfWeek = days[dateObj.getDay()];
  const dayOfMonth = dateObj.getDate();
  const month = months[dateObj.getMonth()];

  return `${dayOfWeek}, ${dayOfMonth}, ${month}`;
};

const formatDateHeader = (value) => {
  const dateString = value;
  const date = new Date(dateString);

  // Convert date to the desired format
  const formattedDate = `${date.toLocaleString('default', {
    month: 'short',
  })}, ${date.getDate()}`;

  return formattedDate;
};

const formatTime = (isoTimestamp) => {
  if (!isoTimestamp) return '';
  if (!isoTimestamp?.includes('T')) return isoTimestamp;
  const date = new Date(isoTimestamp);
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const timeValue = `${formattedHours}:${minutes} ${ampm}`;
  return timeValue;
};

const convertTimeToISO8601DateTime = (time) => {
  if (time === null) return '';
  if (time?.includes('T')) {
    return formatTime(time);
  }
  // Get the current date
  const currentDate = new Date();

  // Parse the input time in "HH:mm" format
  const [hours, minutes] = time?.split(':').map(Number);

  // Set the time to the current date
  currentDate.setHours(hours, minutes, 0, 0);

  // Convert the date object to an ISO 8601 DateTime format
  const iso8601DateTime = currentDate.toISOString();

  return formatTime(iso8601DateTime);
};

const formatDateOnly = (value) => {
  const date = new Date(value);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const BusinessHours = ({ scheduleUpdate }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: '2024-04-22',
    toDate: '2024-04-28',
  });
  const { data, refetch, loading } = useGetBusinessHours(filter);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editableData, setEditTableData] = useState(null);

  const currentWeekNumber = getCurrentWeekCount();
  const [currentWeek, setCurrentWeek] = useState(currentWeekNumber + 1); // Initial week number

  // Function to handle clicking on the left arrow
  const goToPreviousWeek = () => {
    if (currentWeek > 1) {
      setCurrentWeek(currentWeek - 1);
    }
  };

  // Function to handle clicking on the right arrow
  const goToNextWeek = () => {
    if (currentWeek < 52) {
      setCurrentWeek(currentWeek + 1);
    }
  };

  // Function to format week range
  const formatWeekRange = (weekNumber) => {
    const startDate = new Date(`2024-01-01`);
    startDate.setDate(startDate.getDate() + (weekNumber - 1) * 7);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    const startMonth = startDate.toLocaleString('en-us', { month: 'short' });
    const endMonth = endDate.toLocaleString('en-us', { month: 'short' });
    return `Week ${startMonth} ${startDate.getDate()}  to ${endMonth} ${endDate.getDate()}, 2024`;
  };

  const OpenModal = (value) => {
    setEditTableData(value);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (data && data?.businessHoursHistories) {
      const sortedData = sortArrayByDayName(data?.businessHoursHistories);
      setTableData(sortedData);
    }
  }, [data]);

  useEffect(() => {
    // formatWeekRange(currentWeek);
    if (currentWeek) {
      const startDate = new Date(`2024-01-01`);
      startDate.setDate(startDate.getDate() + (currentWeek - 1) * 7);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      setFilter({
        fromDate: formatDateOnly(startDate),
        toDate: formatDateOnly(endDate),
      });
    }
  }, [currentWeek]);

  useEffect(() => {
    refetch();
  }, [scheduleUpdate]);

  return (
    <div>
      {loading && <Spinner />}
      <section className="mt-2">
        <div className="flex justify-end mb-4">
          <div className="bg-white-main py-2 px-6 rounded border border-gray-light font-semibold text-sm flex items-center gap-6">
            <img
              src={leftIcon}
              alt="icon"
              className=" cursor-pointer"
              onClick={goToPreviousWeek}
            />
            <p>{formatWeekRange(currentWeek)}</p>
            <img
              src={rightIcon}
              alt="icon"
              className=" cursor-pointer"
              onClick={goToNextWeek}
            />
          </div>
        </div>
        <div className="border-b border-gray-light -mx-4 md:-mx-8 md:border-b-2 md:border-gray-border"></div>
        <div className="infinite-scroll-component__outerdiv mt-4">
          <div className="infinite-scroll-component ">
            <table className="w-full main-table border-separate hidden md:table">
              <thead>
                <tr className="border-b border-gray-highlight">
                  <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                    Salon Name
                  </th>
                  {tableData?.length ? (
                    tableData?.map((data, index) => {
                      return (
                        <th
                          key={index}
                          className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left pl-3"
                        >
                          {convertToProperCase(data?.shiftDayName) +
                            ' ' +
                            formatDateHeader(data?.shiftDate)}
                        </th>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item">
                  <td className='class="py-2  py-2"'>
                    <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
                      <div className="h-12 w-12 p-1 rounded">
                        <div className="aspect-w-1 aspect-h-1">
                          <img className="rounded" src={category} alt="icon" />
                        </div>
                      </div>

                      <span className="font-semibold text-xs">SalonHQ</span>
                    </div>
                  </td>
                  {tableData?.length ? (
                    tableData?.map((data, index) => {
                      const shiftDate = new Date(data?.shiftDate);
                      const currentDate = new Date();
                      const isPastDate = shiftDate < currentDate;
                      return (
                        <td
                          className="py-2 font-semibold text-xs border-l-gray-light border-l pl-3"
                          key={index}
                        >
                          <div className="grid">
                            {data?.histories?.length &&
                            data?.histories[0]?.title === null ? (
                              data?.histories?.map((item, index) => {
                                return (
                                  <span key={index}>
                                    {convertTimeToISO8601DateTime(
                                      item?.startTime
                                    )}{' '}
                                    -{' '}
                                    {convertTimeToISO8601DateTime(
                                      item?.endTime
                                    )}
                                  </span>
                                );
                              })
                            ) : data?.histories?.length === 1 &&
                              data?.histories[0]?.title !== null ? (
                              <>{data?.histories[0]?.title}</>
                            ) : (
                              <>
                                <span key={index}>Day/Off</span>
                              </>
                            )}

                            {data?.histories?.length &&
                            data.histories[0].title === null &&
                            !isPastDate ? (
                              <button
                                onClick={() => OpenModal(data)}
                                className="step-7"
                              >
                                <EditIcon />
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tr>
              </tbody>
            </table>
            {/* mobile screen html start here */}
            <div className="grid grid-cols-1 gap-6 px-4 md:hidden overflow-x-auto max-height-62 pb-12">
              <div className="grid">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Salon Name</p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2">
                  <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
                    <div className="h-12 w-12 p-1 rounded">
                      <div className="aspect-w-1 aspect-h-1">
                        <img className="rounded" src={category} alt="icon" />
                      </div>
                    </div>

                    <span className="font-semibold text-xs">SalonHQ</span>
                  </div>
                </div>
              </div>
              {tableData?.length ? (
                tableData?.map((data, index) => {
                  const shiftDate = new Date(data?.shiftDate);
                  const currentDate = new Date();
                  const isPastDate = shiftDate < currentDate;
                  return (
                    <div className="grid gap-2" key={index}>
                      <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                        <p>
                          {' '}
                          {convertToProperCase(data?.shiftDayName) +
                            ' ' +
                            formatDateHeader(data?.shiftDate)}{' '}
                        </p>
                      </div>
                      <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                        <div className="flex font-semibold text-xs justify-between items-center">
                          <div className="grid">
                            {data?.histories?.length &&
                            data?.histories[0]?.title === null ? (
                              data?.histories?.map((item, index) => {
                                return (
                                  <span key={index}>
                                    {convertTimeToISO8601DateTime(
                                      item?.startTime
                                    )}{' '}
                                    -{' '}
                                    {convertTimeToISO8601DateTime(
                                      item?.endTime
                                    )}
                                  </span>
                                );
                              })
                            ) : data?.histories?.length === 1 &&
                              data?.histories[0]?.title !== null ? (
                              <>{data?.histories[0]?.title}</>
                            ) : (
                              <>
                                <span key={index}>Day/Off</span>
                              </>
                            )}
                          </div>
                          {data?.histories?.length &&
                          data.histories[0].title === null &&
                          !isPastDate ? (
                            <button
                              onClick={() => OpenModal(data)}
                              className="step-7_1"
                            >
                              <EditIcon />
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              {/* <div className="grid gap-2">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Tuesday 26, Mar </p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                  <div className="flex font-semibold text-xs justify-between items-center">
                    <span>9:00 am - 2:00 pm</span>
                    <EditIcon />
                  </div>
                </div>
              </div>
              <div className="grid gap-2">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Wednesday 27, Mar </p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                  <div className="flex font-semibold text-xs justify-between items-center">
                    <span>9:00 am - 2:00 pm</span>
                    <EditIcon />
                  </div>
                </div>
              </div>
              <div className="grid gap-2">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Thursday 28, Mar </p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                  <div className="flex font-semibold text-xs justify-between items-center">
                    <span>9:00 am - 2:00 pm</span>
                    <EditIcon />
                  </div>
                </div>
              </div>
              <div className="grid gap-2">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Friday 29, Mar </p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                  <div className="flex font-semibold text-xs justify-between items-center">
                    <span>9:00 am - 2:00 pm</span>
                    <EditIcon />
                  </div>
                </div>
              </div>
              <div className="grid gap-2">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Saturday 30, Mar </p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                  <div className="grid font-semibold text-xs text-center">
                    Holiday
                  </div>
                </div>
              </div>
              <div className="grid gap-2">
                <div className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left">
                  <p>Sunday 31, Mar </p>
                </div>
                <div className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item p-2 rounded">
                  <div className="grid font-semibold text-xs text-center">
                    Holiday
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={'md:w-120 w-5/6 bg-white-main'}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 60,
            backdropFilter: 'blur(2px)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            border: '1px solid #ccc',
            backgroundColor: '#fff',
            // overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            paddingRight: '0',
          },
        }}
      >
        <span
          className=" absolute right-4 top-2 font-semibold cursor-pointer"
          onClick={closeModal}
        >
          &#x2715;
        </span>
        <div className=" flex flex-col">
          <h2 className=" font-bold md:text-2xl text-md  text-gray-dark">
            Edit Shift {formatDate(editableData?.shiftDate)}
          </h2>
          <EditForm
            editableData={editableData}
            closeModal={closeModal}
            refetch={refetch}
          />
        </div>
      </ReactModal>
    </div>
  );
};

export default BusinessHours;
