import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';

// Define the GraphQL query
const GET_APPOINTMENTS = gql`
  query AppointmentsList(
    $filter: AppointmentsFilterInput!
    $pagination: PaginationInput!
    $disablePagination: Boolean!
  ) {
    appointments(
      filter: $filter
      pagination: $pagination
      disablePagination: $disablePagination
    ) {
      nodes {
        uid
        startTime
        status
        comment
        sellerService {
          name
          durationInMinutes
          uid
        }
        buyer {
          uid
          formalName
          email
          phone
        }
      }
      pagination @skip(if: $disablePagination) {
        perPage
        lastPage
        nextPage
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

// Custom hook to fetch appointments
export const useGetAppointments = (filter, disablePagination = false) => {
  const { loading, error, data, refetch } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter: filter?.filter,
      pagination: filter?.pagination,
      disablePagination: disablePagination,
    },
  });

  return { loading, error, data, refetch };
};
