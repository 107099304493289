import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { TabsContainer } from '../../components/atoms/tabs';
import { HolidayForm, BusinessHoursForm } from '../../components/templates';
import { Spinner } from '../../components/atoms';
import Joyride, { STATUS, Step } from 'react-joyride';

export const ManageSchedulePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [holidayModalIsOpen, setHolidayModalIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [addHoliday, setAddHoliday] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleUpdate, setScheduleUpdate] = useState(false);

  const OpenModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const OpenHolidayModal = () => {
    setHolidayModalIsOpen(true);
  };
  const closeHolidayModal = () => {
    setHolidayModalIsOpen(false);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [showJoyride, setShowJoyride] = useState(true);
  useEffect(() => {
    // Check localStorage on component mount
    const joyrideCompleted = localStorage.getItem('joyride_schedules');
    if (joyrideCompleted === 'false') {
      setShowJoyride(false);
    }
  }, []);

  // add Joyride here
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
  };
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: (
          <p className="text-xs text-black-main text-left child-div">
            In the weekly schedule editor, select each day of the week to
            customize your business hours. Adjust the start and end times, set
            break intervals, and modify your availability to create a flexible
            and accurate working schedule that meets your needs.
          </p>
        ),

        target: '.step-6',
        title: 'Set Business Hours for Each Day',
        spotlightPadding: 20,
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      // Update localStorage when Joyride finishes
      localStorage.setItem('joyride_schedules', 'false');
      setShowJoyride(false); // Hide Joyride after completion
    }
  };

  useEffect(() => {
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('load', setVh);
      window.removeEventListener('resize', setVh);
    };
  }, []);
  return (
    <>
      {showJoyride && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      {isLoading && <Spinner isBlock={false} />}
      <section className="bg-gray-verylight md:bg-gray-main h-screen  pt-12 md:pt-0 overflow-hidden">
        <div className="pl-4 pr-4  md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-lg text-gray-dark text-left md:text-xl lg:text-2xl truncate">
                Manage Schedule
              </h1>
              <div className="flex flex-row gap-x-3 md:h-7 lg:h-10">
                {currentTab === 1 ? (
                  <button
                    onClick={OpenModal}
                    className=" px-2 pt-1 pb-1  ml-2 sm:w-40 sm:px-0 sm:ml-0 md:h-7 lg:pt-2 lg:pb-2 lg:h-10 whitespace-nowrap guide-add-buyer-button h-7 w-auto border border-primary bg-primary  text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                  >
                    <div className="flex items-center gap-2 w-full justify-center text-14 step-6">
                      <span>+</span>
                      <span>Manage Shifts</span>
                    </div>
                  </button>
                ) : (
                  <button
                    onClick={OpenHolidayModal}
                    className=" px-2 pt-1 pb-1  ml-2 sm:w-40 sm:px-0 sm:ml-0 md:h-7 lg:pt-2 lg:pb-2 lg:h-10 whitespace-nowrap guide-add-buyer-button h-7 w-20 border border-primary bg-primary  text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                  >
                    <div className="flex items-center gap-2 w-full justify-center   text-14">
                      <span>+</span>
                      <span>Add</span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="border-b border-gray-light -mx-4 md:-mx-8 md:border-b-2 md:border-gray-border"></div>
        </div>

        <section className="relative overflow-y-auto pb-22 flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <TabsContainer
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            addHoliday={addHoliday}
            scheduleUpdate={scheduleUpdate}
          />
        </section>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={'md:w-144 w-5/6 bg-white-main max-h-80'}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 31,
              backdropFilter: 'blur(2px)',
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
        >
          <span
            className=" absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={closeModal}
          >
            &#x2715;
          </span>
          <div className=" flex flex-col">
            <h2 className=" font-bold md:text-2xl text-base  text-gray-dark">
              Set Business Working Hours
            </h2>
            <p className=" text-xs mt-2">
              Shift of your salon will occur within these working hours.
            </p>
          </div>
          <BusinessHoursForm
            closeModal={closeModal}
            setScheduleUpdate={setScheduleUpdate}
            scheduleUpdate={scheduleUpdate}
            setIsLoading={setIsLoading}
          />
        </ReactModal>

        <ReactModal
          isOpen={holidayModalIsOpen}
          onRequestClose={closeHolidayModal}
          className={'md:w-120 w-5/6 bg-white-main'}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 31,
              backdropFilter: 'blur(2px)',
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',

              border: '1px solid #ccc',
              backgroundColor: '#fff',
              // overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
        >
          <span
            className=" absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={closeHolidayModal}
          >
            &#x2715;
          </span>
          <div className=" flex flex-col">
            <h2 className=" font-bold md:text-2xl text-lg  text-gray-dark">
              Add Day off/Holidays
            </h2>
            <HolidayForm
              setIsLoading={setIsLoading}
              setAddHoliday={setAddHoliday}
              closeHolidayModal={closeHolidayModal}
            />
          </div>
        </ReactModal>
      </section>
    </>
  );
};
