import React, { useEffect, useState } from 'react';
import Modal from './modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ClientList from './client-list';
import ArchivedList from './archived-list';
import { AddClientForm } from './add-client-form';
import { Spinner } from '../../components/atoms';
import { useGetClients } from '../../hooks';

export const ClientsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchedText, setSearchedText] = useState('');
  const [page, setPage] = useState(1);
  const [newClient, setNewClient] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    filter: {
      nameMatches: '',
    },
    pagination: {
      page: 1,
      perPage: 15,
    },
    archived: false,
  });
  const { data, refetch, loading } = useGetClients(filter);

  const handleTabSelect = (tabIndex) => {
    setCurrentTab(tabIndex);
  };

  const [isAddNewClientModalOpen, setIsAddNewClientModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);

  const openAddNewClientModal = () => {
    setIsAddNewClientModalOpen(true);
    setIsSecondModalOpen(false); // Ensure second modal is closed when opening the AddNewClient modal
  };

  const closeAddNewClientModal = () => setIsAddNewClientModalOpen(false);
  const openSecondModal = () => {
    setIsSecondModalOpen(true);
    setIsAddNewClientModalOpen(false); // Close the AddNewClient modal when opening the second modal
  };
  const closeSecondModal = () => setIsSecondModalOpen(false);

  const fetchMoreData = () => {
    setPage(page + 1);
    setHasMore(false);
  };

  useEffect(() => {
    setSearchedText('');
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === 0) {
      if (searchedText) {
        setPage(1);
      }
      setFilter({
        filter: {
          nameMatches: searchedText,
        },
        pagination: {
          page: page,
          perPage: 15,
        },
        archived: false,
      });
    }
  }, [searchedText, page]);

  useEffect(() => {
    if (currentTab === 0) {
      refetch();
    }
  }, [currentTab]);

  return (
    <>
      {(isLoading || loading) && <Spinner isBlock={false} />}
      <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0">
        <div className="pl-4 pr-4 bg-gray-verylight md:bg-gray-main md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <div>
                <div className="hidden md:block">
                  <span className="text-xs text-gray-middle">Clients</span>
                  <span className="text-xs text-gray-middle mx-1"> &gt;</span>
                  <span className="text-xs text-gray-dark">Client List</span>
                </div>
                <h1 className="font-bold text-lg text-black text-left md:text-xl lg:text-2xl truncate">
                  Client’s List
                </h1>
              </div>
              <div className="flex flex-row gap-x-3 md:h-7 lg:h-10">
                <button
                  onClick={openAddNewClientModal}
                  className=" px-2 pt-1 pb-1  ml-2 w-40 sm:px-0 sm:ml-0 md:h-7 h-7 lg:pt-2 lg:pb-2 lg:h-10 whitespace-nowrap guide-add-buyer-button   border border-primary bg-primary  text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                >
                  <div className="flex items-center gap-2 w-full justify-center   text-14">
                    <span>+</span>
                    <span>Add New Client</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
        <section className="relative flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <div className="my-6 flex justify-end">
            <div className="relative w-full md:w-1/4 flex items-center rounded overflow-hidden border-gray-light border text-r1.125 hover:border-blue-hover hover:text-gray-middle">
              <div className="pr-2 absolute right-0">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M12.0687 12.9807C10.5659 14.1098 8.67466 14.6424 6.78004 14.4702C4.88542 14.298 3.1299 13.4338 1.87093 12.0537C0.611964 10.6735 -0.0557828 8.8812 0.00365277 7.04162C0.0630884 5.20203 0.845238 3.45352 2.19084 2.1521C3.53645 0.850684 5.34433 0.0942198 7.24637 0.0367359C9.14842 -0.0207479 11.0016 0.625071 12.4286 1.84269C13.8556 3.06032 14.7491 4.75819 14.9272 6.59059C15.1052 8.423 14.5545 10.2522 13.3871 11.7056L17.7243 15.8847C18.0914 16.2385 18.092 16.8131 17.7255 17.1675C17.359 17.522 16.7645 17.5217 16.3984 17.1669L12.0781 12.9807H12.0687ZM7.48724 12.6913C8.22396 12.6913 8.95346 12.551 9.63409 12.2783C10.3147 12.0056 10.9332 11.606 11.4541 11.1021C11.975 10.5983 12.3883 10.0002 12.6702 9.34188C12.9521 8.6836 13.0972 7.97806 13.0972 7.26553C13.0972 6.55301 12.9521 5.84746 12.6702 5.18918C12.3883 4.5309 11.975 3.93276 11.4541 3.42893C10.9332 2.9251 10.3147 2.52545 9.63409 2.25277C8.95346 1.9801 8.22396 1.83976 7.48724 1.83976C5.99938 1.83976 4.57246 2.4114 3.52038 3.42893C2.4683 4.44646 1.87725 5.82653 1.87725 7.26553C1.87725 8.70454 2.4683 10.0846 3.52038 11.1021C4.57246 12.1197 5.99938 12.6913 7.48724 12.6913Z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </div>
              <input
                className="pr-6 pl-2 w-full py-1.5 text-sm placeholder-gray-dark font-normal tracking-wide outline-none border-none"
                type="text"
                placeholder="Search"
                value={searchedText}
                onChange={(e) => setSearchedText(e?.target?.value)}
              />
            </div>
          </div>
          {currentTab === 0 && (
            <div className="border-b border-gray-light  md:border-b-2 md:border-gray-border"></div>
          )}
          <Tabs
            selectedIndex={currentTab}
            onSelect={handleTabSelect}
            className="mt-2"
          >
            <TabList className=" border-0 flex gap-3">
              <Tab className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto">
                <div
                  className={
                    currentTab === 0
                      ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary text-center'
                      : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle text-center'
                  }
                >
                  Client
                </div>
              </Tab>
              <Tab className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto">
                <div
                  className={
                    currentTab === 1
                      ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary text-center'
                      : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle text-center'
                  }
                >
                  Archived Client
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <ClientList
                searchedText={searchedText}
                data={data}
                page={page}
                setHasMore={setHasMore}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                setIsLoading={setIsLoading}
                setPage={setPage}
                refetch={refetch}
                newClient={newClient}
              />
            </TabPanel>
            <TabPanel>
              <ArchivedList
                setIsLoading={setIsLoading}
                searchedText={searchedText}
              />
            </TabPanel>
          </Tabs>
        </section>
        {/* Add New Client modal start here  */}
        <Modal
          isOpen={isAddNewClientModalOpen}
          onClose={closeAddNewClientModal}
        >
          <AddClientForm
            setIsLoading={setIsLoading}
            closeAddNewClientModal={closeAddNewClientModal}
            refetch={refetch}
            setPage={setPage}
            setNewClient={setNewClient}
            newClient={newClient}
            pageView="client-list"
          />
        </Modal>
        <Modal isOpen={isSecondModalOpen} onClose={closeSecondModal}>
          <h2 className="font-semibold text-center text-base">
            Client details has been added successfully
          </h2>
          <button
            className="h-10 w-full border mt-10 bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
            onClick={closeSecondModal}
          >
            OK
          </button>
        </Modal>
      </section>
    </>
  );
};
