import React from 'react';

import { SchedulerNavigation } from '../../molecules';
import { MenuSeparator, CompanyLogo, CloseIcon } from '../../atoms';

export const SideBarTemplate = ({ data = {}, onCloseSideBar }) => {
  return (
    <div className="flex flex-col md:justify-between w-full">
      <div className="">
        {onCloseSideBar && (
          <div
            className="absolute top-0 right-0 p-2 cursor-pointer md:hidden"
            onClick={onCloseSideBar}
          >
            <CloseIcon className="transform scale-75 opacity-50 text-gray-dark" />
          </div>
        )}
        <div className="flex items-center py-5 md:py-0 md:pt-4">
          <CompanyLogo />
        </div>

        <MenuSeparator />

        <SchedulerNavigation />
      </div>
    </div>
  );
};
