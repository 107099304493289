import React from 'react';
import SubscriptionCheckout from '../../components/templates/subscription-checkout/subscription-checkout';

const SchedulerPage = ({ isSubscribed, isSessionCreated }) => {
  return (
    <>
      <section className="grid place-content-center h-screen p-4">
        <div>
          <h2 className="text-3xl font-semibold text-center mb-6">
            Subscription Plan
          </h2>
          <SubscriptionCheckout
            isSubscribed={isSubscribed}
            isSessionCreated={isSessionCreated}
          />
        </div>
      </section>
    </>
  );
};

export default SchedulerPage;
