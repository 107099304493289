import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import edit from '../../assets/images/create-outline.svg';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import category from '../../assets/images/dummyImage.png';
import examinationIcon from '../../assets/images/examinationIcon.svg';
import ArchivedIcon from '../../components/atoms/icons/archived.icon';
import { Archived } from '../../components/atoms/icons';
import { categoryFormSchema } from '../../constants';
import { FormImage } from '../../components/molecules';
import {
  useGetCategories,
  useCreateCategory,
  useDeleteCategory,
  useUpdateCatgeory,
  useUnArchiveCategory,
} from '../../hooks';
import { showError, showSuccess } from '../../utils';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CROPPER_PROPS } from '../../constants';
import { Spinner } from '../../components/atoms';
import Joyride, { STATUS, Step } from 'react-joyride';

export const SchedulerCategoriesPage = () => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    pagination: {
      page: 1,
      perPage: 15,
    },
    archived: false,
  });
  const { data, refetch, loading } = useGetCategories(filter);
  const handleCreateCategory = useCreateCategory();
  const handleDeleteCategory = useDeleteCategory();
  const handleUpdateCategory = useUpdateCatgeory();
  const handleUnArchiveCategory = useUnArchiveCategory();
  const [currentTab, setCurrentTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [trashModalIsOpen, setTrashModalIsOpen] = useState(false);
  const [restoreCategoryModal, setRestoreCategoryModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [fileModalIsOpen, setFileModalIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [contentType, setContentType] = useState('');
  const [fileName, setFileName] = useState('');
  const [formChanged, setFormChanged] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [signedBlobId, setSignedBlobId] = useState(null);
  const [cropperBlob, setCropperBlob] = useState(null);

  const formParams = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(categoryFormSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = formParams;

  const deleteOpenModal = () => {
    setTrashModalIsOpen(true);
  };
  const deletecloseModal = () => {
    setTrashModalIsOpen(false);
  };

  const RestoreCategoryCloseModal = () => {
    setRestoreCategoryModal(false);
  };
  const RestoreCategoryOpenModal = () => {
    setRestoreCategoryModal(true);
  };

  const handleArchiveCategory = async (data) => {
    setIsLoading(true);
    const payload = {
      categoryUid: data,
    };
    const response = await handleDeleteCategory(payload);
    if (response?.data?.removeCategory?.status === 'OK') {
      setPage(1);
      setCategories([]);
      refetch();
      closeModal();
      setIsLoading(false);
      showSuccess('Category archived successfully');
      // deleteOpenModal();
    } else {
      setIsLoading(false);
      showError('Something went wrong');
    }
  };

  const handleRestoreCategory = async () => {
    setIsLoading(true);
    const payload = {
      categoryUid: categoryId,
    };
    const response = await handleUnArchiveCategory(payload);
    if (response?.data?.unarchiveCategory?.status === 'OK') {
      setPage(1);
      setCategories([]);
      showSuccess('Category unarchived successfully');
      refetch();
      setIsLoading(false);
      RestoreCategoryCloseModal();
    } else {
      setIsLoading(false);
      showError('Something went wrong');
    }
  };

  const handleUpdateCategoryFunction = async (data) => {
    setIsLoading(true);
    const payload = {
      categoryUid: categoryData?.uid,
      input: {
        name: data?.categoryName,
        cropperBlob: cropperBlob,
        signedBlobId: categoryData?.coverImage?.signedBlobId,
        logo: watch('logo'),
      },
    };

    setPage(1);
    const response = await handleUpdateCategory(payload);
    if (response?.data?.updateCategory?.uid) {
      setCategories([]);
      setImage(null);
      showSuccess('Category updated successfully');
      refetch();
      setIsLoading(false);
      FileCloseModal();
      setCropperBlob(null);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  const OpenModal = (value) => {
    setCategoryId(value);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setCategoryId(null);
    setModalIsOpen(false);
  };

  const FileOpenModal = () => {
    setImage(null);
    reset();
    setCategoryData();
    setFileModalIsOpen(true);
  };

  const editCategoryFunction = (data) => {
    reset();
    setCategoryData(data);
    setFileModalIsOpen(true);
  };

  const FileCloseModal = () => {
    reset();
    setImage(null);
    setCategoryData(null);
    setFileModalIsOpen(false);
  };

  useEffect(() => {
    if (data && data?.categories?.nodes) {
      setCategories((prevCategories) => [
        ...prevCategories,
        ...data.categories.nodes,
      ]);
      if (data?.categories?.pagination?.totalPages !== page) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [data]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = {
      name: data?.categoryName,
      cropperBlob: cropperBlob,
    };
    const response = await handleCreateCategory(payload);
    if (response?.data?.createCategory?.uid) {
      setCategories([]);
      setImage(null);
      showSuccess('Category Added Successfully');
      refetch();
      reset();
      setIsLoading(false);
      FileCloseModal();
      setCropperBlob(null);
    } else {
      setIsLoading(false);
      showError(response);
    }
  };

  useEffect(() => {
    if (categoryData?.name) {
      setValue('categoryName', categoryData?.name);
      setValue('logo', categoryData?.coverImage?.url);
    }
  }, [categoryData]);

  useEffect(() => {
    const category = watch('categoryName');
    const image = watch('logo');
    if (
      category === categoryData?.name &&
      image === categoryData?.coverImage?.url
    ) {
      setFormChanged(false);
    } else {
      setFormChanged(true);
    }
  }, [watch('categoryName'), watch('logo')]);

  useEffect(() => {
    if (currentTab === 1) {
      setFilter({
        pagination: {
          page: page,
          perPage: 15,
        },
        archived: false,
      });
      refetch();
    } else {
      setFilter({
        pagination: {
          page: page,
          perPage: 15,
        },
        archived: true,
      });
      refetch();
    }
  }, [currentTab, page]);

  const fetchMoreData = () => {
    setPage(page + 1);
    setHasMore(false);
  };

  // Function to handle sorting
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  // Function to sort data based on current sorting state
  const sortedData = categories.slice().sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn].charAt(0).toLowerCase(); // Get the first letter and convert to lowercase
      const bValue = b[sortColumn].charAt(0).toLowerCase(); // Get the first letter and convert to lowercase
      if (sortOrder === 'asc') {
        return aValue.localeCompare(bValue); // Use localeCompare for string comparison
      } else {
        return bValue.localeCompare(aValue); // In descending order, reverse the comparison
      }
    }
    return 0;
  });

  const [showJoyride, setShowJoyride] = useState(true);
  useEffect(() => {
    // Check localStorage on component mount
    const joyrideCompleted = localStorage.getItem('joyride_categories');
    if (joyrideCompleted === 'false') {
      setShowJoyride(false);
    }
  }, []);

  // add Joyride here
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
  };
  const [run, setRun] = useState(true); // Ensure run is true initially to start Joyride
  const [steps] = useState([
    {
      content: (
        <div className="p-0 child-div">
          <p className="text-xs text-black-main text-left">
            Expand your service offerings by creating a new category tailored to
            your expertise and client needs. Click on the &quot;Add&quot; button
            to begin crafting your unique category. By clicking on the
            &quot;Add&quot; button and defining the category name and image.
          </p>
        </div>
      ),
      target: '.step-3',
      title: 'Create a New Category',
      disableBeacon: true,
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false); // Set run to false to stop Joyride after completion or skip
      // Update localStorage when Joyride finishes
      localStorage.setItem('joyride_categories', 'false');
      setShowJoyride(false); // Hide Joyride after completion
    }
  };

  useEffect(() => {
    // Start Joyride on component mount
    setRun(true);
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    window.addEventListener('load', setVh);
    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('load', setVh);
      window.removeEventListener('resize', setVh);
    };
  }, []);
  return (
    <>
      {showJoyride && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      {(loading || isLoading) && <Spinner className="loader-cls" />}
      <section className="flex flex-col relative h-screen bg-gray-verylight pt-12 md:pt-0 md:bg-gray-main px-3 md:px-0 overflow-hidden">
        <div className="pl-4 pr-4  md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-lg text-gray-dark text-left md:text-xl lg:text-2xl truncate">
                Categories
              </h1>
              <div className="flex flex-row gap-x-3 md:h-7 lg:h-10">
                <button
                  onClick={FileOpenModal}
                  className="step-3 px-2 pt-1 pb-1  ml-2 sm:w-40 sm:px-0 sm:ml-0 md:h-7 h-7 lg:pt-2 lg:pb-2 lg:h-10 whitespace-nowrap guide-add-buyer-button w-20  border border-primary bg-primary  text-white-main text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
                >
                  <div className="flex items-center gap-2 w-full justify-center   text-14">
                    <span>+</span>
                    <span>Add</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-light -mx-4 md:-mx-8 md:border-b-2 md:border-gray-border"></div>
        </div>

        <section className="relative flex-grow md:pl-8 md:pr-8 pl-2 pr-2">
          <Tabs className="mt-2">
            <TabList className=" border-0 flex gap-3">
              <Tab className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto">
                <div
                  onClick={() => {
                    setPage(1);
                    setCategories([]);
                    setCurrentTab(1);
                  }}
                  className={
                    currentTab === 1
                      ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary text-center'
                      : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle text-center'
                  }
                >
                  Category
                </div>
              </Tab>
              <Tab className=" border-0 bg-transparent cursor-pointer focus:outline-none w-1/2 md:w-auto">
                <div
                  onClick={() => {
                    setPage(1);
                    setCategories([]);
                    setCurrentTab(2);
                  }}
                  className={
                    currentTab === 2
                      ? 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-primary border-b-3 border-primary text-center'
                      : 'text-sm font-bold pb-2 md:pb-3 px-1 focus:outline-none capitalize text-gray-dark hover:text-gray-middle text-center'
                  }
                >
                  Archived Category
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <section className="relative overflow-y-auto pb-22 flex-grow ">
                <div className="infinite-scroll-component__outerdiv">
                  <div
                    className="infinite-scroll-component "
                    id="scrollable-container"
                    style={{ height: 'calc(100vh - 156px)', overflow: 'auto' }}
                  >
                    <InfiniteScroll
                      dataLength={sortedData?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={<h4>Nothing more to show</h4>}
                      scrollableTarget="scrollable-container"
                      className="pb-12"
                    >
                      <table className="w-full main-table border-separate">
                        <thead className="bg-gray-verylight sticky top-0 z-10">
                          <tr className="border-b border-gray-highlight">
                            <th
                              className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left cursor-pointer"
                              onClick={() => handleSort('name')}
                            >
                              Category{' '}
                              {sortColumn === 'name' &&
                                sortOrder === 'asc' &&
                                '▲'}
                              {sortColumn === 'name' &&
                                sortOrder === 'desc' &&
                                '▼'}
                            </th>
                            <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs ">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedData &&
                            !loading &&
                            sortedData?.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item"
                                >
                                  <td className='class="py-2  py-2"'>
                                    <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
                                      <div className="h-12 w-12 p-1 rounded">
                                        <div className="aspect-w-1 aspect-h-1">
                                          <img
                                            className="rounded"
                                            src={
                                              item?.coverImage?.url || category
                                            }
                                            alt="icon"
                                          />
                                        </div>
                                      </div>

                                      <span className="font-semibold text-xs">
                                        {item?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-right pl-1 md:pl-5 pr-3 md:pr-5 w-20 py-2">
                                    {!item?.isDefault && (
                                      <div className="flex justify-end md:justify-start gap-x-2 md:gap-x-4">
                                        <div className="flex guide-featured-brand step-4">
                                          <img
                                            className="min-w-r10"
                                            src={edit}
                                            alt="icon"
                                            onClick={() =>
                                              editCategoryFunction(item)
                                            }
                                          />
                                        </div>
                                        <div
                                          className="flex guide-featured-brand step-5"
                                          onClick={() => {
                                            if (
                                              item?.sellerServicesCount !== 0
                                            ) {
                                              OpenModal(item?.uid);
                                            } else {
                                              setCategoryId(item?.uid);
                                              handleArchiveCategory(item?.uid);
                                            }
                                          }}
                                        >
                                          <ArchivedIcon />
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          {!sortedData && !loading && (
                            <tr>
                              <td
                                colSpan="2"
                                className=" h-50 w-full text-center"
                              >
                                <p className="font-semibold">
                                  No categories are available please add the
                                  category
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </div>
              </section>
            </TabPanel>
            <TabPanel>
              <section className="relative overflow-y-auto pb-22 flex-grow ">
                <div className="infinite-scroll-component__outerdiv ">
                  <div
                    className="infinite-scroll-component"
                    id="scrollable-container-archived"
                    style={{ height: 'calc(100vh - 156px)', overflow: 'auto' }}
                  >
                    <InfiniteScroll
                      dataLength={sortedData?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      // loader={<h4>Loading...</h4>}
                      // endMessage={<h4>Nothing more to show</h4>}
                      scrollableTarget="scrollable-container-archived"
                      className=" pb-12"
                    >
                      <table className="w-full main-table border-separate">
                        <thead className="bg-gray-verylight sticky top-0 z-10">
                          <tr className="border-b border-gray-highlight">
                            <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs text-left cursor-pointer">
                              Category
                            </th>
                            <th className="text-r0.625 font-semibold text-gray-middle tracking-wider truncate md:text-xs ">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        {sortedData &&
                          !loading &&
                          sortedData?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className="text-gray-dark cursor-pointer hover:bg-gray-lightHover bg-white-main shadow-items table-list-item"
                              >
                                <td className='class="py-2  py-2"'>
                                  <div className="flex items-center justify-start gap-x-2 md:gap-x-3">
                                    <div className="h-12 w-12 p-1 rounded">
                                      <div className="aspect-w-1 aspect-h-1">
                                        <img
                                          className="rounded"
                                          src={
                                            item?.coverImage?.url || category
                                          }
                                          alt="icon"
                                        />
                                      </div>
                                    </div>

                                    <span className="font-semibold text-xs">
                                      {item?.name}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-right pl-1 md:pl-5 pr-3 md:pr-5 w-20 py-2">
                                  {!item?.isDefault && (
                                    <div className="flex justify-end md:justify-start gap-x-2 md:gap-x-4">
                                      <div
                                        className="flex guide-featured-brand"
                                        onClick={() => {
                                          if (item?.sellerServicesCount !== 0) {
                                            OpenModal(item?.uid);
                                          } else {
                                            setCategoryId(item?.uid);
                                            // handleRestoreCategory(item?.uid);
                                            RestoreCategoryOpenModal();
                                          }
                                        }}
                                      >
                                        <Archived />
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        {!sortedData && !loading && (
                          <tr>
                            <td
                              colSpan="2"
                              className=" h-50 w-full text-center"
                            >
                              <p className="font-semibold">
                                Categories are currently unavailable in the
                                archive.
                              </p>
                            </td>
                          </tr>
                        )}
                      </table>
                    </InfiniteScroll>
                  </div>
                </div>
              </section>
            </TabPanel>
          </Tabs>
        </section>
        {/* delete modal goes here */}

        <ReactModal
          isOpen={restoreCategoryModal}
          onRequestClose={RestoreCategoryCloseModal}
          className={'md:w-404 w-5/6 bg-white-main'}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 31,
              backdropFilter: 'blur(2px)',
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
        >
          <div className="flex justify-between items-center mb-6">
            <span
              className="absolute right-4 top-2 font-semibold cursor-pointer"
              onClick={RestoreCategoryCloseModal}
            >
              &#x2715;
            </span>
          </div>
          <div className="text-center mt-3">
            <p className="font-semibold">
              Are you sure you want to move archived <br />
              category to active category?
            </p>
          </div>

          <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6">
            <button
              type="button"
              onClick={RestoreCategoryCloseModal}
              className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
            >
              No
            </button>
            <button
              type="submit"
              onClick={handleRestoreCategory}
              className="flex-1 md:flex-none h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
            >
              Yes
            </button>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={trashModalIsOpen}
          onRequestClose={deletecloseModal}
          className={'md:w-3/12 w-5/6 bg-white-main'}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 31,
              backdropFilter: 'blur(2px)',
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
        >
          <span
            className=" absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={deletecloseModal}
          >
            &#x2715;
          </span>
          <div className="text-center mt-3">
            <p>Your category is archived</p>
          </div>
          <div className="grid  mt-8">
            <button
              className=" h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
              onClick={deletecloseModal}
            >
              Ok
            </button>
            {/* <button
                className="w-100 rounded p-2 border-red-main border bg-red-main text-white-main"
                onClick={deletecloseModal}
              >
                Delete
              </button> */}
          </div>
        </ReactModal>
        {/* secound modal goes here */}
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className={'md:w-120 w-5/6 bg-white-main'}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 31,
              backdropFilter: 'blur(2px)',
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              // width: '440px',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
        >
          <span
            className=" absolute right-4 top-2 font-semibold cursor-pointer"
            onClick={closeModal}
          >
            &#x2715;
          </span>
          <div className="text-center mt-3 flex flex-col justify-center items-center">
            <img src={examinationIcon} />
            <h2 className=" font-semibold text-base mt-3">
              The category you&apos;ve selected has already been utilized for
              the services. Are you sure you want to archive the category?
            </h2>
            <p className="mt-4 text-xs">
              <span className="font-semibold">Note:</span> Services will be
              moved to the General Category.
            </p>
            <div className="grid grid-cols-2  gap-2  md:gap-8 mt-6 w-full">
              <button
                type="button"
                onClick={closeModal}
                className="flex-1 md:flex-none h-10 w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => handleArchiveCategory(categoryId)}
                className="flex-1 md:flex-none h-10 w-full border  bg-primary text-white-main  text-xs hover:bg-blue-hover py-2 text-center font-bold rounded-md"
              >
                Archive
              </button>
            </div>
          </div>
        </ReactModal>
        {/* file uploder modal goes here */}
        <ReactModal
          isOpen={fileModalIsOpen}
          onRequestClose={FileCloseModal}
          className={'md:w-28 w-5/6 bg-white-main'}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 31,
              backdropFilter: 'blur(2px)',
              display: 'grid',
              placeItems: 'center',
            },
            content: {
              position: 'relative',
              // width: '440px',
              // maxHeight: '570px',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
        >
          <span
            className=" absolute right-3 top-2 font-semibold cursor-pointer"
            onClick={FileCloseModal}
          >
            &#x2715;
          </span>
          <FormProvider {...formParams}>
            <form
              onSubmit={handleSubmit(
                categoryData ? handleUpdateCategoryFunction : onSubmit
              )}
            >
              <div className="mt-3 flex flex-col justify-center items-center">
                <div className=" relative w-full">
                  <div className="block gap-4 lg:block">
                    <FormImage
                      extendImage
                      size="full"
                      name="logo"
                      // label="Category Image"
                      blobIdName="brandLogoBlob"
                      widthClassName="w-full"
                      // heightClassName="h-34 lg:h-96"
                      cropperProps={CROPPER_PROPS}
                      setSignedBlobId={setSignedBlobId}
                      setCropperBlob={setCropperBlob}
                    />
                    <div className="flex-col flex text-xs text-gray-middle mt-4 lg:flex-row lg:justify-between ">
                      <div>File Supported: PNG, JPEG, JPG</div>
                      <div>Maximum size: 5MB</div>
                    </div>
                  </div>
                  <Controller
                    name="categoryName" // Provide a unique name for the input
                    control={control}
                    defaultValue="" // Set the default value if needed
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder="Category Name"
                        className="w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 my-3"
                      />
                    )}
                  />
                  {errors && errors?.categoryName && (
                    <p className="text-red-drk text-r0.6875">
                      {errors?.categoryName?.message}
                    </p>
                  )}
                </div>
                <button
                  disabled={!(formChanged || image) || errors?.categoryName}
                  className={`flex-1 md:flex-none h-10 w-full border b py-2 text-center font-bold rounded-md ${
                    !(formChanged || image) || errors?.categoryName
                      ? 'text-gray-dark text-xs py-2 text-center font-bold rounded-md bg-gray-mercury'
                      : 'order-primary bg-primary text-white-main text-xs hover:bg-blue-hover'
                  }`}
                >
                  Save
                </button>
              </div>
            </form>
          </FormProvider>
        </ReactModal>
      </section>
    </>
  );
};
