import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CalendarIcon from '../../assets/images/calendar_today.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Spinner } from '../../components/atoms';
import moment from 'moment'; // Assuming you are using Moment.js

ChartJS.register(ArcElement, Tooltip, Legend);

const STATUS_COLORS = {
  accepted: '#2c802a',
  rescheduled: '#2f80ed',
  completed: '#8f8e8e',
  canceled: '#d9000d',
};

const AppointmentAnalysis = ({ data, onDateRangeChange, loading }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState('');

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    if (start && end && start.getTime() === end.getTime()) {
      const endOfDay = new Date(start);
      endOfDay.setHours(23, 59, 59, 999);

      setStartDate(start);
      setEndDate(endOfDay);
      onDateRangeChange(start.toISOString(), endOfDay.toISOString());

      setDisplayDateRange(
        `${moment(start).format('MMM DD')} - ${moment(endOfDay).format(
          'MMM DD'
        )}`
      );

      setIsOpen(false);
    } else {
      setStartDate(start);
      setEndDate(end);
      onDateRangeChange(
        start ? start.toISOString() : null,
        end ? end.toISOString() : null
      );

      if (start && end) {
        setDisplayDateRange(
          `${moment(start).format('MMM DD')} - ${moment(end).format('MMM DD')}`
        );
      } else if (start && !end) {
        setDisplayDateRange(`${moment(start).format('MMM DD')}`);
      } else {
        setDisplayDateRange('');
      }

      if (end) {
        setIsOpen(false);
      }
    }
  };

  const handleResetDateRange = () => {
    setStartDate(null);
    setEndDate(null);
    setDisplayDateRange('');
    onDateRangeChange(null, null);
  };

  const chartData = {
    labels: data?.map(
      (item) => item.label.charAt(0).toUpperCase() + item.label.slice(1)
    ),
    datasets: [
      {
        data: data?.map((item) => item.value),
        backgroundColor: data?.map(
          (item) => STATUS_COLORS[item.label.toLowerCase()]
        ),
        borderColor: data?.map(
          (item) => STATUS_COLORS[item.label.toLowerCase()]
        ),
        borderWidth: 1,
        cutout: '60%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  const showNoDataMessage = chartData?.datasets[0]?.data?.every(
    (count) => count === 0
  );

  return (
    <>
      {loading && <Spinner />}

      <div className="px-6 py-5 bg-white-main mt-4 rounded min-h-r11.125">
        <h4 className="lg:text-lg text-sm text-gray-dark font-semibold flex items-center">
          Appointment Analysis
        </h4>

        <div className="relative date-wrapper flex justify-end my-4 items-center">
          {displayDateRange && (
            <div className="mr-3 border border-primary bg-primaryOpacity15 text-xs pl-4 pr-7 py-1 rounded-10 relative inline-block text-black-main">
              <span
                className="close absolute right-1 top-1 font-semibold cursor-pointer capsul"
                onClick={handleResetDateRange}
              >
                &#x2715;
              </span>
              {displayDateRange}
            </div>
          )}
          <button
            onClick={toggleDatePicker}
            className="flex items-center justify-center py-2 px-4 gap-2 text-gray-dark font-semibold text-xs border rounded-md border-gray-border bg-white-main"
          >
            <img src={CalendarIcon} alt="calendar icon" />
            <span>Select Date Range</span>
          </button>
          {isOpen && (
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              withPortal
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-start"
              shouldCloseOnSelect={true}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '300px',
          }}
        >
          {showNoDataMessage ? (
            <p>No data available</p>
          ) : (
            <Doughnut data={chartData} options={options} />
          )}
        </div>
      </div>
    </>
  );
};

export default AppointmentAnalysis;
