import React from 'react';

const DollarModalIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="40"
        viewBox="0 0 36 40"
        fill="none"
      >
        <path
          d="M28.0094 28.0054H32.0094V4.0055H12.0094V8.0055H28.0094V28.0054ZM28.0094 32.0054V38.0038C28.0094 39.1092 27.1094 40.0054 25.9956 40.0054H2.02309C0.910966 40.0054 0.00976562 39.1162 0.00976562 38.0038L0.0149655 10.0072C0.0151855 8.9017 0.915045 8.0055 2.02861 8.0055H8.00937V2.0055C8.00937 0.90092 8.90481 0.00549984 10.0094 0.00549984H34.0094C35.114 0.00549984 36.0094 0.90092 36.0094 2.0055V30.0054C36.0094 31.11 35.114 32.0054 34.0094 32.0054H28.0094ZM4.01461 12.0055L4.01015 36.0054H24.0094V12.0055H4.01461ZM8.00937 28.0054H17.0094C17.5616 28.0054 18.0094 27.5578 18.0094 27.0054C18.0094 26.4532 17.5616 26.0054 17.0094 26.0054H11.0094C8.24794 26.0054 6.00937 23.767 6.00937 21.0054C6.00937 18.244 8.24794 16.0054 11.0094 16.0054H12.0094V14.0055H16.0094V16.0054H20.0094V20.0054H11.0094C10.4571 20.0054 10.0094 20.4532 10.0094 21.0054C10.0094 21.5578 10.4571 22.0054 11.0094 22.0054H17.0094C19.7708 22.0054 22.0094 24.244 22.0094 27.0054C22.0094 29.767 19.7708 32.0054 17.0094 32.0054H16.0094V34.0054H12.0094V32.0054H8.00937V28.0054Z"
          fill="#8E8E9F"
        />
      </svg>
    </div>
  );
};

export default DollarModalIcon;
