import React from 'react';
import SubscriptionCheckout from '../../components/templates/subscription-checkout/subscription-checkout';

export const ManageSubscription = () => {
  return (
    <div>
      <section className="bg-gray-verylight md:bg-gray-main h-screen  pt-12 md:pt-0">
        <div className="pl-4 pr-4  md:pl-8 md:pr-8">
          <div className="pb-3 pt-3  w-full md:pb-5 md:pt-5 lg:pb-7 lg:pt-8 relative gap-x-2">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-lg text-gray-dark text-left md:text-xl lg:text-2xl truncate">
                Manage Subscription
              </h1>
            </div>
          </div>
          <div className="border-b border-gray-light -mx-4 md:-mx-8 md:border-b-2 md:border-gray-border"></div>
        </div>
        <SubscriptionCheckout />
      </section>
    </div>
  );
};
