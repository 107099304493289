import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DateFilterForm = ({
  setAppointmentData,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  closeCalModal,
  setPage,
}) => {
  const [startDateValue, setStartValue] = useState(startDate);
  const [endDateValue, setEndValue] = useState(endDate);
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    if (!startDateValue || !endDateValue) {
      setStartDate(null);
      setEndDate(null);
      setPage(1);
      closeCalModal();
      return;
    }
    setAppointmentData([]);
    setPage(1);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
    closeCalModal();
  };

  return (
    <>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 mobile-date-picker">
          <p className=" font-semibold md:text-2xl text-base">
            Select Date Range
          </p>
          <DatePicker
            selected={startDateValue}
            onChange={(date) => setStartValue(date)}
            dateFormat="MM/dd/yyyy"
            placeholderText="From"
            maxDate={endDateValue}
            autoComplete="off"
            onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
            className="w-full text-left px-3 cursor-default border"
          />

          <DatePicker
            selected={endDateValue}
            onChange={(date) => setEndValue(date)}
            dateFormat="MM/dd/yyyy"
            placeholderText="To"
            minDate={startDateValue}
            autoComplete="off"
            onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
            className="w-full text-left px-3 cursor-default border"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-12">
          <button
            onClick={() => {
              setStartValue(null);
              setEndValue(null);
            }}
            disabled={!(startDateValue && endDateValue)}
            className={`${
              !(startDateValue && endDateValue) && 'opacity-40'
            } w-full  border border-gray-dark bg-very-light text-gray-dark text-xs hover:text-gray-middle hover:border-very-dark py-2 text-center font-bold rounded-md`}
          >
            Clear{' '}
          </button>
          <button
            className="w-full p-2  rounded-lg bg-primary text-white-main hover:bg-blue-hover"
            // className={
            //   !(startDateValue && endDateValue)
            //     ? 'text-gray-dark w-full p-2  rounded-lg bg-gray-mercury'
            //     : ' w-full p-2  rounded-lg bg-primary text-white-main hover:bg-blue-hover'
            // }
            // disabled={!(startDateValue && endDateValue)}
          >
            Apply
          </button>
        </div>
      </form>
    </>
  );
};
