import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cardIcon from '../../assets/images/card-successful.png';

const PaymentSuccessfull = () => {
  useEffect(() => {
    localStorage.setItem('isSubscribed', true);
  }, []);

  return (
    <div className="grid h-screen content-center justify-center">
      <div className="text-center">
        <img className="m-auto mb-4" src={cardIcon} alt="logo" />
        <h2 className="text-black font-semibold">
          Your Subscription has been successfully created.
        </h2>
        <h2 className="text-black font-semibold">
          You may now access the Scheduler Module.
        </h2>
        <h2 className="text-black font-semibold">
          <Link
            to="/service"
            className="text-blue-paypal cursor-pointer underline"
          >
            Click here
          </Link>{' '}
          to begin.
        </h2>
      </div>
    </div>
  );
};

export default PaymentSuccessfull;
