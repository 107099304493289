import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useBlobUpload } from '../images';

const REJECT_APPOINTMENT = gql`
  mutation RejectAppointment(
    $appointmentUid: String!
    $input: RejectAppointmentInput!
  ) {
    rejectAppointment(appointmentUid: $appointmentUid, input: $input) {
      uid
      status
      startTime
      status
      comment
      sellerService {
        name
        durationInMinutes
      }
      buyer {
        uid
        formalName
        email
        phone
      }
    }
  }
`;

export const useRejectAppointment = () => {
  const [rejectAppointmentMutation] = useMutation(REJECT_APPOINTMENT); // Destructure error from useMutation

  const handleRejectAppointment = async (input) => {
    try {
      const response = await rejectAppointmentMutation({
        variables: {
          appointmentUid: input?.appointmentUid,
          input: {
            comment: input?.comment,
          },
        },
      });
      return response;
    } catch (err) {
      console.error('Error rejecting appointment:', err);

      // Check for GraphQL errors
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // Handle GraphQL errors
        return err.graphQLErrors[0]?.message;
        // You can choose to handle specific error codes or messages here
        // For instance, you can throw a custom error or return a specific response
      }

      // Check for network errors
      if (err.networkError) {
        // Handle network errors
        console.error('Network error:', err.networkError);
        // You can choose to handle network errors differently
        // For instance, you can throw a custom error or return a specific response
      }

      // Handle the error appropriately (e.g., display a user-friendly message)
      return null; // Or throw a custom error for further handling
    }
  };

  return handleRejectAppointment;
};
