import React, { useEffect, useState } from 'react';
import LeftIcon from '../../assets/images/left-arw.png';
import RightIcon from '../../assets/images/right-arw.png';
import { ColoredSlug, Spinner } from '../../components/atoms';
import { useGetAppointments } from '../../hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const getCurrentWeek = () => {
  const now = new Date();
  const dayOfWeek = now.getDay() === 0 ? 6 : now.getDay() - 1; // Convert Sunday (0) to 6 and other days (1-6) to 0-5
  const startOfWeek = new Date(now.setDate(now.getDate() - dayOfWeek));
  const week = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    week.push(day);
  }
  return week;
};

const getNextWeek = (currentWeek) => {
  const startOfNextWeek = new Date(currentWeek[0]);
  startOfNextWeek.setDate(startOfNextWeek.getDate() + 7);
  return getWeekFromDate(startOfNextWeek);
};

const getPrevWeek = (currentWeek) => {
  const startOfPrevWeek = new Date(currentWeek[0]);
  startOfPrevWeek.setDate(startOfPrevWeek.getDate() - 7);
  return getWeekFromDate(startOfPrevWeek);
};

const getWeekFromDate = (date) => {
  const dayOfWeek = date.getDay() === 0 ? 6 : date.getDay() - 1; // Convert Sunday (0) to 6 and other days (1-6) to 0-5
  const startOfWeek = new Date(date.setDate(date.getDate() - dayOfWeek));
  const week = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    week.push(day);
  }
  return week;
};

const formatDateToApi = (date) => {
  const startTimeGteq = moment(date).startOf('day').toISOString();
  const startTimeLteq = moment(date).endOf('day').toISOString();

  return {
    startTimeGteq,
    startTimeLteq,
  };
};

export const UpcomingAppointments = () => {
  const navigate = useNavigate();
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointmentData, setAppointmentData] = useState([]);

  const [filter, setFilter] = useState({
    filter: {
      ...formatDateToApi(selectedDate),
      nameMatches: '',
    },
    pagination: {},
  });

  const { data, refetch, loading } = useGetAppointments(filter, true);

  useEffect(() => {
    if (data?.appointments?.nodes) {
      const now = moment();
      const upcomingAppointments = data.appointments.nodes.filter(
        (appointment) =>
          moment(appointment.startTime).isSameOrAfter(now) &&
          ['rescheduled', 'accepted'].includes(appointment.status)
      );
      setAppointmentData(upcomingAppointments);
    }
  }, [data]);

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        ...formatDateToApi(selectedDate),
      },
    }));
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate !== null) {
      refetch(); // Refetch whenever selected date changes
    }
  }, [filter, refetch]);

  const handleNextWeek = () => {
    setCurrentWeek(getNextWeek(currentWeek));
  };

  const selectedWeekNumber = moment(currentWeek[0]).isoWeek();
  const currentWeekNumber = moment().isoWeek();

  const handlePrevWeek = () => {
    if (selectedWeekNumber > currentWeekNumber) {
      setCurrentWeek(getPrevWeek(currentWeek));
    }
  };

  const handleDateClick = (date) => {
    const today = moment().startOf('day');
    if (moment(date).isSameOrAfter(today)) {
      setSelectedDate(date);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="bg-white-main p-4 rounded">
        <h4 className="text-black-secondary font-semibold text-base">
          Upcoming Appointments
        </h4>
        <div className="my-4">
          <div className="calander-box mt-8 md:w-full">
            <div className="flex gap-4 justify-between items-center w-full">
              <p className="md:text-sm text-xs font-semibold">
                {formatDate(selectedDate)}
              </p>
              <div className="flex gap-4">
                <img
                  src={LeftIcon}
                  alt="icon"
                  className="cursor-pointer"
                  style={{
                    opacity: selectedWeekNumber <= currentWeekNumber ? 0.3 : 1,
                  }}
                  onClick={handlePrevWeek}
                />
                <img
                  src={RightIcon}
                  alt="icon"
                  className="cursor-pointer"
                  onClick={handleNextWeek}
                />
              </div>
            </div>
            <div className="calendar-date grid gap-1 grid-cols-calenderBox items-center mt-3">
              {currentWeek.map((day, index) => {
                const isSelected =
                  day.toDateString() === selectedDate.toDateString();
                const isPastDate = moment(day).isBefore(
                  moment().startOf('day')
                );
                return (
                  <div
                    key={index}
                    className={`grid border ${
                      isSelected
                        ? 'border-blue-bar bg-primary'
                        : 'border-purple-bdr'
                    } rounded-xl px-1 py-2 text-center cursor-pointer w-full hover:border-blue-bar hover:bg-blue-calBg ${
                      isPastDate ? 'pointer-events-none opacity-50' : ''
                    }`}
                    onClick={() => handleDateClick(day)}
                  >
                    <p
                      className={`${
                        isSelected ? 'text-white-main' : 'text-purple-light'
                      } text-xs`}
                    >
                      {day.toLocaleDateString('en-US', { weekday: 'short' })}
                    </p>
                    <p
                      className={`${
                        isSelected ? 'text-white-main' : 'text-black-secondary'
                      } font-semibold text-base`}
                    >
                      {day.getDate()}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="border-t border-blue-draft mt-5 overflow-y-auto scroll-box pb-5">
          {appointmentData?.length > 0 ? (
            appointmentData.map((appointment, index) => (
              <div
                key={index}
                className="flex gap-3 items-center w-full p-4 border-b border-blue-draft cursor-pointer"
                onClick={() =>
                  navigate('/appointment-details', { state: appointment })
                }
              >
                <div className="h-12 w-12 p-1 rounded-lg">
                  <div className="aspect-w-1 aspect-h-1 rounded-lg">
                    <ColoredSlug name={appointment?.buyer?.formalName} />
                  </div>
                </div>
                <div className="w-full">
                  <h4 className="md:text-base text-sm font-semibold">
                    {appointment?.buyer?.formalName}
                  </h4>
                  <div className="flex justify-between">
                    <p className="text-gray-dark text-xs">
                      {appointment?.sellerService?.name}
                    </p>
                    <p className="text-xs text-gryt">
                      {moment(appointment?.startTime).format('hh:mm A')}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-4 text-center text-gray-dark">
              No appointment scheduled
            </div>
          )}
        </div>
      </div>
    </>
  );
};
